import { Injectable } from '@angular/core';
import { AgreementApi, ResponseAgreementDto, UpdateAgreementDto } from '../../../../../../../../generated/apex-rest';
import { restAxiosConfig } from '../../../../utils/rest-axios-config';

@Injectable()
export class AgreementRestService {
  private readonly agreementApi = new AgreementApi(restAxiosConfig());

  async read(objectId: number): Promise<ResponseAgreementDto> {
    const response = await this.agreementApi.agreementControllerRead(objectId);

    return response.data.Entity;
  }

  async updateObjectField(agreementId: number, updateAgreementDto: UpdateAgreementDto): Promise<void> {
    await this.agreementApi.agreementControllerUpdate(agreementId, updateAgreementDto);
  }
}
