<ng-container *ngIf="project$ | async as project; else notLoaded">
  <ng-container *ngIf="fields$ | async as fields; else notLoaded">
    <div class="am">
      <h2 class="">
        <T
          str="Add disciplines to {name}"
          [vars]="{ name: project.name }"></T>
      </h2>

      <div class="amb apt df jsb">
        <button
          class="amr"
          type="button"
          mat-raised-button
          [disabled]="saving"
          (click)="toggleSelect()">
          <T
            str="Select all"
            *ngIf="!anySelected()">
          </T>
          <T
            str="Select none"
            *ngIf="anySelected()">
          </T>
        </button>

        <button
          type="button"
          mat-raised-button
          color="primary"
          [disabled]="!anySelected() || saving"
          (click)="addFields()">
          <T str="Add disciplines"></T>
        </button>
      </div>

      <div class="display-grid">
        <ng-container *ngFor="let field of fields">
          <form
            #fieldForm="ngForm"
            class="df f1 w ap mat-elevation-z2 c"
            [class.bg-selected-primary-50]="selected[field.id]">
            <div class="amr ambs">
              <mat-checkbox
                name="add"
                [disabled]="saving"
                [matTooltip]="'Select' | translate"
                [(ngModel)]="selected[field.id]">
              </mat-checkbox>
            </div>
            <div class="df f1 c">
              <mat-form-field
                class="p-rm"
                floatLabel="always">
                <mat-label>
                  <T str="Name"></T>
                </mat-label>
                <span matTextPrefix>{{ objectFields[field.id].basePrefix }}&nbsp;</span>
                <input
                  matInput
                  name="name"
                  [(ngModel)]="objectFields[field.id].name" />
              </mat-form-field>
              <mat-form-field class="">
                <mat-label>
                  <T str="Color"></T>
                </mat-label>
                <input
                  matInput
                  type="text"
                  name="color"
                  [(ngModel)]="objectFields[field.id].actualColor"
                  [pattern]="hexColorPattern" />
                <mat-error>
                  <T str="Color value seems wrong"></T>
                </mat-error>
                <apex-color-picker
                  class="amrs"
                  matIconSuffix
                  name="colorPicker"
                  [(ngModel)]="objectFields[field.id].actualColor">
                </apex-color-picker>
              </mat-form-field>

              <div class="amb">
                <h3 class="amvs">
                  <T str="Contractors"></T>
                </h3>

                <apex-autocomplete
                  #contractorAuto
                  type="User"
                  [label]="'New contractor' | translate"
                  name="contractor"
                  (modelChange)="addContractor($event, contractorAuto, objectFields[field.id])">
                </apex-autocomplete>

                <div
                  *ngFor="let contractor of objectFields[field.id].Contractors"
                  class="df ac">
                  <div class="f1">{{ contractor.name }} ({{ contractor.Customer?.name }})</div>
                  <button
                    mat-icon-button
                    (click)="removeContractor(contractor, objectFields[field.id])">
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
              </div>

              <div class="mat-caption">
                <T
                  str="In use {count, plural, =0 {0 times} =1 {1 time} other {# times}}"
                  [vars]="{ count: inUseCounts[field.id] ?? 0 }">
                </T>
              </div>
            </div>
          </form>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #notLoaded>
  <div class="bg-color-error am">
    <T str="Failure in getting data."></T>
  </div>
</ng-template>
