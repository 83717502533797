import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { CollectionResponse } from 'projects/apex/src/app/utils/types';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getObjectId } from '../../utils';
import { Agreement } from './agreement.model';
import { AgreementService } from './agreement.service';

const getAgreementId = (route: ActivatedRouteSnapshot): number => Number(route.paramMap.get('aid')) ?? 0;

const tryGetObjectId = (route: ActivatedRouteSnapshot): number => {
  let objectId = 0;

  try {
    objectId = getObjectId(route);
  } catch (err) {
    // unable to get parent object id -> access from portal
  }

  return objectId;
};

export const AgreementResolver: ResolveFn<Agreement> = (route: ActivatedRouteSnapshot) => {
  const service = inject(AgreementService);

  const oid = tryGetObjectId(route);
  const aid = getAgreementId(route);

  return !aid ? of(new Agreement()) : service.getByObject(oid, aid).pipe(map((a) => a.Entity));
};

export const AgreementsResolver: ResolveFn<CollectionResponse<Agreement>> = (route: ActivatedRouteSnapshot) => {
  const service = inject(AgreementService);

  const { agreementPage, agreementLimit, agreementSearch, agreementActive, agreementRequireReports } =
    route.queryParams;

  return service.queryByObject(
    tryGetObjectId(route),
    agreementPage,
    agreementLimit,
    agreementSearch,
    agreementActive,
    agreementRequireReports,
  );
};
