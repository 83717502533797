import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { of } from 'rxjs';
import { Field } from '../../models/field';
import { FieldService } from './field.service';

export const FieldGetByQueryParamResolver: ResolveFn<Field> = (route: ActivatedRouteSnapshot) => {
  const fieldService = inject(FieldService);

  return Number(route.queryParams?.Field) ? fieldService.get(Number(route.queryParams.Field)) : of(undefined);
};

export const FieldsResolver: ResolveFn<Field[]> = () => {
  const fieldService = inject(FieldService);

  return fieldService.query();
};
