<div
  #wrap
  (apexInView)="inView = $event; loader()"
  inViewOnlyOnce
  class="wrap mat-elevation-z2 f1 oh"
  [ngClass]="{
    grid: view === FileUsageViewType.Grid,
    list: view === FileUsageViewType.List,
    single: view === FileUsageViewType.Single,
    over: dropOver,
    valid: dropValid,
    invalid: dropInvalid,
    disabled: disabled,
  }"
  (tap)="tapped = !tapped"
  (mouseover)="hover = true"
  (mouseleave)="hover = false">
  <div
    class="toolbar df jsb ac oh"
    [ngClass]="{ 'toolbar-overlay': view === 'single', 'toolbar-overlay-show': hover || tapped }">
    <div class="df jsb ac oh">
      <mat-icon
        *ngIf="disabled"
        matTooltipPosition="after"
        [matTooltip]="'This file usage is locked and cannot be edited' | translate">
        lock
      </mat-icon>
      <div class="title df c oh">
        <span class="mat-subtitle-2 rm truncate">{{ title }}</span>
        <ng-container *ngIf="description">
          <span class="mat-caption green-background amtxs truncate">{{ description }}</span>
        </ng-container>
      </div>
    </div>

    <div class="actions df">
      <button
        type="button"
        mat-icon-button
        [disabled]="uploading || !fileUsages.length"
        [matTooltip]="'View' | translate"
        (click)="viewFiles()">
        <mat-icon>remove_red_eye</mat-icon>
      </button>

      <button
        type="button"
        mat-icon-button
        [disabled]="uploading || disabled"
        (click)="fileUploadInput.click()"
        [matTooltip]="'Upload' | translate">
        <mat-icon>cloud_upload</mat-icon>
      </button>

      <button
        type="button"
        mat-icon-button
        [disabled]="disableToolsAsClient || uploading || disabled"
        (click)="openFolder()"
        [matTooltip]="'Insert files from Folders' | translate">
        <mat-icon>drive_folder_upload</mat-icon>
      </button>

      <button
        type="button"
        mat-icon-button
        [matMenuTriggerFor]="actionsMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #actionsMenu="matMenu">
        <button
          type="button"
          mat-menu-item
          [disabled]="!anySelected() || uploading"
          [matTooltip]="'Clear selected' | translate"
          (click)="selected = {}; selectionChanged.emit(selected)">
          <mat-icon>clear</mat-icon>
          <span> <T str="Clear selected"></T> ({{ manySelected() }}) </span>
        </button>

        <button
          type="button"
          mat-menu-item
          [disabled]="!anySelected() || disableToolsAsClient || uploading || !allowEdit || disabled"
          [matTooltip]="'Delete' | translate"
          class="mat-warn"
          (click)="delete()">
          <mat-icon>delete</mat-icon>
          <span> <T str="Delete"></T> ({{ manySelected() }}) </span>
        </button>

        <mat-divider></mat-divider>

        <button
          type="button"
          mat-menu-item
          [disabled]="uploading || view === FileUsageViewType.List"
          (click)="view = FileUsageViewType.List"
          [matTooltip]="'List' | translate">
          <mat-icon>view_list</mat-icon>
          <span>
            <T str="List"></T>
          </span>
        </button>

        <button
          type="button"
          mat-menu-item
          [disabled]="uploading || view === FileUsageViewType.Grid"
          (click)="view = FileUsageViewType.Grid"
          [matTooltip]="'Grid' | translate">
          <mat-icon>view_module</mat-icon>
          <span>
            <T str="Grid"></T>
          </span>
        </button>

        <button
          type="button"
          mat-menu-item
          [disabled]="uploading || view === FileUsageViewType.Single"
          (click)="selected = []; view = FileUsageViewType.Single; selectionChanged.emit(selected)"
          [matTooltip]="'Single' | translate">
          <mat-icon fontSet="material-icons-sharp">crop_original</mat-icon>
          <span>
            <T str="Single"></T>
          </span>
        </button>

        <mat-divider></mat-divider>

        <button
          type="button"
          mat-menu-item
          [disabled]="disableToolsAsClient || sortBy === SortBy.Sort"
          (click)="sortBy = SortBy.Sort; sortFileUsages()"
          [matTooltip]="'Sort by custom' | translate">
          <mat-icon>sort</mat-icon>
          <span>
            <T str="Custom"></T>
          </span>
        </button>

        <button
          type="button"
          mat-menu-item
          [disabled]="disableToolsAsClient || sortBy === SortBy.Date"
          (click)="sortBy = SortBy.Date; sortFileUsages()"
          [matTooltip]="'Sort by date' | translate">
          <mat-icon>sort</mat-icon>
          <span>
            <T str="Date"></T>
          </span>
        </button>

        <button
          type="button"
          mat-menu-item
          [disabled]="disableToolsAsClient || sortBy === SortBy.Name"
          (click)="sortBy = SortBy.Name; sortFileUsages()"
          [matTooltip]="'Sort by name' | translate">
          <mat-icon>sort</mat-icon>
          <span>
            <T str="Name"></T>
          </span>
        </button>

        <mat-divider></mat-divider>

        <button
          type="button"
          mat-menu-item
          [disabled]="!validType('image/*')"
          (click)="editFileUploadInput.click()"
          [matTooltip]="'Upload a new image' | translate">
          <mat-icon>upload</mat-icon>
          <span>
            <T str="Upload a new image"></T>
          </span>
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="loader">
    <mat-progress-bar
      mode="indeterminate"
      *ngIf="loading || uploading || (view === FileUsageViewType.List && anyUpdating())">
    </mat-progress-bar>
  </div>

  <div class="content">
    <div
      class="usages grid df scrollable-horizontal"
      *ngIf="view === FileUsageViewType.Grid">
      <div *ngFor="let fileUsage of fileUsages">
        <div
          class="usage"
          [class.mat-elevation-z8]="selected[fileUsage.id]"
          [ngClass]="{ selected: selected[fileUsage.id] }"
          (click)="onClick($event, fileUsage)">
          <apex-file-preview
            [fileUsage]="fileUsage"
            [file]="fileUsage.File"
            size="212"
            [showSize]="true"
            [showDates]="true">
          </apex-file-preview>
          <div class="position-absolute file-preview-icon-bottom add-padding-bottom-small fg-light-text">
            <ng-container *ngTemplateOutlet="editDownloadButtons; context: { fileUsage }"></ng-container>
          </div>
          <div
            class="file-name"
            (click)="onClick($event, null)">
            <mat-form-field
              subscriptSizing="dynamic"
              appearance="fill">
              <mat-label>
                <T str="File name"></T>
              </mat-label>
              <input
                matInput
                [disabled]="!allowEdit"
                [(ngModel)]="fileUsage.fileName"
                name="fileName"
                (keyup)="onChange($event, fileUsage)" />
            </mat-form-field>
          </div>
          <div class="status">
            <mat-progress-spinner
              mode="indeterminate"
              diameter="24"
              *ngIf="updating[fileUsage.id]"></mat-progress-spinner>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="view === FileUsageViewType.List"
      (cdkDropListDropped)="drop($event)"
      cdkDropList>
      <div
        class="r df aps jsb"
        cdkDrag
        [cdkDragDisabled]="disabled || sortBy !== SortBy.Sort"
        *ngFor="let fileUsage of fileUsages">
        <div class="df f1 truncate">
          <div class="c asc">
            <mat-icon
              [class.disabled]="disabled || sortBy !== SortBy.Sort"
              cdkDragHandle>
              drag_indicator
            </mat-icon>
          </div>
          <apex-file-preview
            class="c asc amhs"
            [fileUsage]="fileUsage"
            [file]="fileUsage.File"
            size="32">
          </apex-file-preview>

          <div class="c oh asc amlxs">
            <div class="truncate">
              {{ fileUsage.fileName }}
            </div>

            <div class="df truncate oh mat-caption">
              <div [matTooltip]="'File added' | translate">
                {{ fileUsage.createdAt | date: 'shortDate' }}
              </div>
              -
              <div
                class="truncate"
                [matTooltip]="'File size' | translate">
                {{ fileUsage?.File?.size ?? 0 | fileSize: true }}
              </div>
            </div>
          </div>
        </div>

        <div class="hide-on-drag r df ac">
          <ng-container *ngTemplateOutlet="editDownloadButtons; context: { fileUsage }"></ng-container>

          <mat-checkbox
            class="c"
            [(ngModel)]="selected[fileUsage.id]"
            (ngModelChange)="selectionChanged.emit(selected)">
          </mat-checkbox>
        </div>
      </div>
    </div>

    <div
      *ngIf="view === FileUsageViewType.Single"
      class="overlay-single">
      <div
        class="overlay-single-background"
        [ngClass]="{
          over: dropOver,
          valid: dropValid,
          invalid: dropInvalid,
        }"></div>
      <div
        *ngIf="fileUsages.length > 0 && dropOver"
        class="overlay-single-text df ac">
        <T
          str="Drop to upload file"
          *ngIf="dropValid"></T>
        <T
          str="File is invalid"
          *ngIf="!dropValid"></T>
      </div>
    </div>

    <div
      class="usage single"
      *ngIf="view === FileUsageViewType.Single && fileUsages.length">
      <apex-file-usage-preview
        [fileUsages]="fileUsages"
        [selectedIndex]="singleIndex ? singleIndex : 0"
        (viewIndexChanged)="singleIndex = $event"
        [use1kImage]="true"
        [useOldImageLoader]="useOldImageLoader">
      </apex-file-usage-preview>
    </div>

    <div
      class="overlay"
      *ngIf="uploading">
      <T str="Uploading"></T>
    </div>

    <div
      class="empty"
      *ngIf="!loading && !fileUsages.length && !disabled"
      (click)="fileUploadInput.click()">
      <div>
        <mat-icon>cloud_upload</mat-icon>
      </div>
      <div>
        <T str="No files yet, click or drag files here to add"></T>
      </div>
    </div>
    <div
      class="empty"
      *ngIf="disabled && !fileUsages.length">
      <div></div>
      <div>
        <T str="No files yet"></T>
      </div>
    </div>
  </div>
</div>
<input
  #fileUploadInput
  class="dn"
  type="file"
  [disabled]="disabled"
  [accept]="acceptPattern"
  multiple
  (change)="onFileInput($event)" />

<input
  #editFileUploadInput
  class="dn"
  type="file"
  [disabled]="disabled"
  accept="image/*"
  (change)="onEditFileInput($event)" />

<ng-template
  #editDownloadButtons
  let-fileUsage="fileUsage">
  <button
    class="down-button"
    type="button"
    mat-icon-button
    (click)="$event.stopPropagation(); download(fileUsage)"
    [matTooltip]="'Download' | translate">
    <mat-icon>cloud_download</mat-icon>
  </button>
  <button
    [disabled]="disabled || !allowEdit || !isFileUsageImage(fileUsage)"
    type="button"
    class="edit-button"
    mat-icon-button
    (click)="$event.stopPropagation(); editImage(fileUsage)"
    [matTooltip]="'Edit' | translate">
    <mat-icon>edit</mat-icon>
  </button>
</ng-template>
