<div mat-dialog-title>
  <h1 class="df f1 jsb ac">
    <T str="Missing reports"></T>
  </h1>

  <button
    mat-icon-button
    (click)="dialogRef.close()">
    <mat-icon>clear</mat-icon>
  </button>
</div>

<div mat-dialog-content>
  <p>
    <T str="Selected agreement requires uploading reports to complete this case."></T>
  </p>
</div>

<div mat-dialog-actions>
  <div class="df jfe f1">
    <button
      type="button"
      mat-raised-button
      [mat-dialog-close]="{ delete: true }">
      <T str="Cancel"></T>
    </button>
  </div>
</div>
