import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { orderBy } from 'lodash-es';
import { AutocompleteComponent } from 'projects/apex/src/app/components/autocomplete/autocomplete.component';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { Autocomplete } from 'projects/apex/src/app/models/autocomplete';
import { Field } from 'projects/apex/src/app/models/field';
import { ObjectField } from 'projects/apex/src/app/models/object-field';
import { User } from 'projects/apex/src/app/models/user';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { constants } from 'projects/apex/src/app/utils/constants';
import { Observable, combineLatest, from, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { Project } from '../project.model';
import { ObjectFieldService } from './object-field.service';

interface IRouteData {
  project: Project;
  fields: Field[];
  objectFields: ObjectField[];
}

type RouteData = Observable<IRouteData>;

@Component({
  selector: 'apex-add-object-fields',
  templateUrl: './add.component.html',
})
export class AddObjectFieldsComponent {
  project$: Observable<Project>;
  fields$: Observable<Field[]>;
  objectFields$: Observable<ObjectField[]>;

  hexColorPattern = constants.pattern.hexColor;

  selected: Record<number, boolean> = {};
  inUseCounts: Record<number, number> = {};
  objectFields: Record<number, ObjectField> = {};

  saving = false;

  private routeData$: RouteData;

  constructor(
    private route: ActivatedRoute,
    private ofService: ObjectFieldService,
    private userService: UserService,
  ) {
    this.routeData$ = this.route.data as RouteData;

    this.project$ = this.routeData$.pipe(map((data) => data?.project));

    this.objectFields$ = this.routeData$.pipe(map((data) => data?.objectFields));

    this.fields$ = combineLatest([this.project$, this.routeData$, this.objectFields$]).pipe(
      map(
        ([project, data, objectFields]) =>
          data?.fields.map((field) => {
            this.objectFields[field.id] = new ObjectField({
              ObjectId: project.id,
              Object: project,

              Field: field,
              FieldId: field.id,

              name: '',
              color: '',

              Contractors: [],
            });

            this.inUseCounts[field.id] = objectFields.filter((oField) => oField.FieldId === field.id).length;

            return field;
          }) ?? [],
      ),
      map((fields) => fields.filter((field) => field.status === 1)),
      map((fields) => orderBy(fields, ['name'], ['asc'])),
    );
  }

  toggleSelect(): void {
    const on = !this.anySelected();

    from(this.fields$)
      .pipe(take(1))
      .subscribe((fields) =>
        fields.forEach((field) => {
          this.selected[field.id] = on;
        }),
      );
  }

  anySelected(): boolean {
    return Object.values(this.selected).some((v) => !!v);
  }

  addFields(): void {
    this.saving = true;

    const selectedObjectFields = Object.keys(this.selected)
      .filter((k) => this.selected[k])
      .map((k) => this.objectFields[Number(k)]);

    from(selectedObjectFields)
      .pipe(
        mergeMap((objectField) =>
          this.ofService.save(objectField).pipe(
            catchError((err) => {
              snackErr(
                t('Failed to save "{name}"', {
                  name: objectField.fullName ?? objectField.name ?? '',
                }),
                err,
              );

              return of(null);
            }),
          ),
        ),
      )
      .subscribe({
        next: (objectField) => {
          if (objectField) {
            this.inUseCounts[objectField.FieldId]++;
          }
        },
        complete: () => {
          snack(t('All fields saved'));

          this.saving = false;
        },
        error: () => {
          this.saving = false;
        },
      });
  }

  addContractor(a: Autocomplete, ac: AutocompleteComponent, objectField: ObjectField): void {
    if (a) {
      ac?.writeValue(null);

      const existingUser = objectField.Contractors?.find((c) => c.id === a.id);

      if (!existingUser) {
        this.userService.get(a.id).subscribe({
          next: (user) => {
            if (objectField.Contractors) {
              objectField.Contractors.unshift(user);
            } else {
              objectField.Contractors = [user];
            }

            ac?.blur();
          },
        });
      }
    }
  }

  removeContractor(user: User, objectField: ObjectField): void {
    const idx = objectField.Contractors.indexOf(user);

    if (idx !== -1) {
      objectField.Contractors.splice(idx, 1);
    }
  }
}
