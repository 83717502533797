import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { NavigationExtras } from '@angular/router';
import { cloneDeep, isEmpty, orderBy } from 'lodash-es';
import moment, { Moment } from 'moment';
import { FileUsageComponent } from 'projects/apex/src/app/components/file-usage/file-usage.component';
import { Case, Contractor } from 'projects/apex/src/app/models/case';
import { CaseLog } from 'projects/apex/src/app/models/case-log';
import { File } from 'projects/apex/src/app/models/file';
import { FileUsage } from 'projects/apex/src/app/models/file-usage';
import { Marking, MarkingModelType } from 'projects/apex/src/app/models/marking';
import { User } from 'projects/apex/src/app/models/user';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { Observable, Subject, Subscription, of, timer } from 'rxjs';
import { catchError, debounce, filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Color } from '../../../components/charts/charts.colors';
import { EmbeddedMarkingsViewerComponent } from '../../../components/embedded-markings-viewer/embedded-markings-viewer.component';
import { FileUsageViewerDialogComponent } from '../../../components/file-usage-viewer/file-usage-viewer-dialog.component';
import {
  FileUsageViewerData,
  FileUsageViewerMode,
} from '../../../components/file-usage-viewer/file-usage-viewer.types';
import { FileUsageService } from '../../../components/file-usage/file-usage.service';
import { FileService } from '../../../components/file-usage/file.service';
import { t } from '../../../components/translate/translate.function';
import { WarningDialogComponent } from '../../../components/warning-dialog/warning-dialog.component';
import { ObjectField } from '../../../models/object-field';
import { snack, snackErr } from '../../../modules/snack.module';
import { SystemLogMessages } from '../../../pipes/format-case-log-message/system-log-messages';
import { constants } from '../../../utils/constants';
import { getUserRelationForCase } from '../../../utils/functions';
import { openPinturaEditor } from '../../../utils/pintura/pintura';
import { Project } from '../../object/project/project.model';
import { EntryCardComponent } from '../../object/project/tenancy/entry-card/form.component';
import { CaseService } from '../case.service';
import { CaseContractorsComponent } from '../contractor/list.component';
import { CaseFormComponent } from '../form/form.component';
import { RepeatableCase } from '../repeatable-case.model';
import { CaseStatusMessageDialogComponent } from './status-message-dialog.component';

@Component({
  selector: 'apex-case-view',
  templateUrl: './view.component.html',
})
export class CaseViewComponent implements OnInit, OnDestroy {
  @Input() highlighted: boolean;

  @Output() caseChange = new EventEmitter<Case>();
  @Output() printClicked = new EventEmitter();
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() markedAsUnread = new EventEmitter();
  @Output() closeDialogEvent = new EventEmitter();
  @Output() navigateOnClose = new EventEmitter<{ commands: (string | number)[]; extras?: NavigationExtras }>();

  @Output() idChange = new EventEmitter<number>();
  @Output() selectedTabIndexChange = new EventEmitter<number>();

  @ViewChild('caseFiles') caseFiles: FileUsageComponent;
  @ViewChild('formComponent') formComponent: CaseFormComponent;
  @ViewChild('matTabGroup') matTabGroup: MatTabGroup;
  @ViewChild('marking') marking: EmbeddedMarkingsViewerComponent;
  @ViewChild('scroll') scroll: ElementRef;
  @ViewChild('caseContractors') caseContractors: CaseContractorsComponent;
  @ViewChild('entryCard') entryCardComponent: EntryCardComponent;
  @ViewChild('caseAgreementReports') caseAgreementReports: FileUsageComponent;

  SystemLogMessages = SystemLogMessages;

  idValue: number;

  agreementReports: FileUsage[] = [];

  @Input() get id(): number {
    return this.idValue;
  }

  set id(id: number) {
    this.idValue = id;
    this.idChange.emit(this.id);

    if (this.id) {
      this.setCase(this.id);
    }
  }

  caseValue: Case;

  @Input() get case(): Case {
    return this.caseValue;
  }

  set case(c: Case) {
    this.caseValue = c;
    this.loading = false;
    this.caseChange.emit(this.case);

    if (this.case) {
      this.formCase = cloneDeep(this.case);
      this.setCaseFloorplans(this.case);
      this.case.CaseLogs = this.case?.CaseLogs?.length ? orderBy(this.case.CaseLogs, ['createdAt'], ['desc']) : [];
      this.getAccess();
      this.getFiles();

      if (this.matTabGroup) {
        this.matTabGroup.selectedIndex = 0;
      }

      if (this.scroll?.nativeElement) {
        this.scroll.nativeElement.scrollTop = 0;
      }

      if (this.case?.id && !this.case.notifyContractor) {
        this.case.notifyContractor = true;
      }
    }

    if (!!this.caseValue?.ObjectField) {
      this.caseValue.ObjectField = new ObjectField(this.caseValue.ObjectField);
    }
  }

  caseFloorplans: FileUsage[];
  profile: User;

  newContractorId: number;
  userCanAcceptOrDecline: boolean;
  userCanComplete: boolean;
  userCanEdit: boolean;
  userIsContractor: boolean;

  loading = false;
  formCase: Case;

  moment: Moment;

  // @todo Is this even in use?
  Color = Color;

  user$$: Subscription = new Subscription();

  get markingIsDisabled(): boolean {
    const { caseManager, contractor, admin } = this.case.access;

    return !(caseManager || contractor || admin);
  }

  getCaseRequest$ = new Subject<number>();
  getCaseResponse$: Subscription = this.getCaseRequest$
    .pipe(
      tap(() => {
        this.loading = true;
        this.ref.detectChanges();
      }),
      debounce((id: number) => {
        if (id) {
          return timer(constants.requestDebounceTime);
        }

        return of(id);
      }),
      switchMap((id: number) => {
        if (id) {
          return this.caseService.get(id).pipe(catchError(() => of(undefined)));
        }

        return of(undefined);
      }),
      tap(() => {
        this.loading = false;
      }),
    )
    .subscribe({
      next: (c: Case) => {
        this.case = c;
      },
    });

  MarkingModelType = MarkingModelType;

  private subscription = new Subscription();

  constructor(
    private caseService: CaseService,
    private userService: UserService,
    private fileService: FileService,
    private fileUsageService: FileUsageService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.user$$.add(
      this.userService.filteredProfile$.subscribe((user: User) => {
        this.profile = user;
      }),
    );
  }

  userLink(user: User): string {
    return `<a [routerLink]="['/', 'company', ${user.CustomerId}, 'user', case.User.id]"
              [apexUserCard]="case.User">
              {{ case.User.name }}
            </a>`;
  }

  setCase(id: number): void {
    this.getCaseRequest$.next(id);
  }

  setCaseFloorplans(c: Case): void {
    const caseFloorplans = [];

    c?.Markings?.forEach((m: Marking) => {
      if (m.FileUsage) {
        const clonedCase = cloneDeep(c);
        const marking = cloneDeep(m);

        delete clonedCase.Markings;

        marking.Case = clonedCase;

        delete marking.FileUsage;

        const existingFloorplan = caseFloorplans.find((cf) => Number(cf.id) === Number(m.FileUsageId));

        if (existingFloorplan) {
          if (!existingFloorplan.Markings?.length) {
            existingFloorplan.Markings = [];
          }

          existingFloorplan.Markings = existingFloorplan.Markings.concat([marking]);
        } else {
          m.FileUsage.Markings = [marking];
          caseFloorplans.push(m.FileUsage);
        }
      }
    });
    c?.Floorplans?.forEach((f: FileUsage) => {
      const existingFloorplan = caseFloorplans.find((cf) => Number(cf.id) === Number(f.id));

      if (!existingFloorplan) {
        caseFloorplans.push(cloneDeep(f));
      }
    });
    this.caseFloorplans = caseFloorplans;
  }

  addContractor(contractor: Contractor): void {
    this.caseService
      .addContractor(
        this.case.id,
        contractor.id,
        contractor.CaseContractor?.notifyOnAddEmail ?? false,
        contractor.CaseContractor?.notifyOnAddSms ?? false,
      )
      .subscribe({
        next: (c: Case) => {
          this.case = c;
          this.formCase = c;
          this.caseChange.emit(this.case);
          this.getAccess();

          snack(t('Contractor added'));
        },
        error: (err) => snackErr(t('Could not add contractor'), err),
      });
  }

  removeContractor(contractor: Contractor): void {
    this.caseService.removeContractor(this.case.id, contractor.id).subscribe({
      next: (c: Case) => {
        this.case = c;
        this.formCase = c;
        this.caseChange.emit(this.case);
        this.getAccess();

        snack(t('Contractor removed'));
      },
      error: (err) => snackErr(t('Could not remove contractor'), err),
    });
  }

  editContractor(contractor: Contractor): void {
    this.subscription.add(
      this.caseService.postContractor(this.case.id, contractor).subscribe({
        next: (cc) => {
          const cont = this.case.Contractors?.find((c) => c.id === cc.UserId);

          cont.CaseContractor = cc;
        },
      }),
    );
  }

  getFiles(): void {
    /*
    if (Array.isArray(this.case?.files)) {
      const files = [];
      from(this.case.files)
      .pipe(concatMap(id => this.fileService.get(id)))
      .subscribe({
        next: (f: File) => {
          files.push(f);
        },
        complete: () => {
          if (this.case) {
            this.case.Files = files;
            this.formComponent?.updateFileUsage(files);
          }
        }
      });
    }
    */
  }

  updateMarkings(markings?: Marking[]): void {
    if (this.case) {
      if (markings) {
        this.case.Markings = markings.map((m) => {
          const fileUsage = cloneDeep(this.caseFloorplans.find((f) => f.id === m.FileUsageId));

          delete fileUsage.Markings;
          m.FileUsage = fileUsage;

          return m;
        });
        this.caseChange.emit(this.case);
        this.caseFloorplans = this.caseFloorplans.map((cf: FileUsage) => {
          cf.Markings = markings.filter(
            (m) => m.FileUsageId === cf.id && m.model === 'case' && m.modelId === this.case.id,
          );

          return cf;
        });
      } else {
        this.case.Markings = [].concat(
          ...this.caseFloorplans.map((cf) =>
            cf.Markings.map((m) => {
              m.FileUsage = cf;

              return m;
            }),
          ),
        );
        this.marking.getFirstMarkingAndPreview();
        this.caseChange.emit(this.case);
      }
    }
  }

  attachFiles(): void {
    this.case.files = this.case.files.concat(this.caseFiles.fileUsages.map((f) => f.FileId));
    this.caseService.attachFiles(this.case).subscribe((c) => {
      this.case.files = c.files;
      this.caseFiles.fileUsages = [];
      this.getFiles();
    });
  }

  removeFile(file: File): void {
    this.case.files = this.case.files.filter((f) => +f !== file.id);
    this.caseService.removeFiles(this.case).subscribe((c) => {
      this.case.files = c.files;
      this.getFiles();
    });
  }

  addNewMessage(caseLog: CaseLog): void {
    this.case.CaseLogs.unshift(caseLog);
  }

  accept(): void {
    this.caseService.accept(this.case).subscribe((c) => {
      this.case = c;
    });
  }

  decline(): void {
    this.dialog
      .open(CaseStatusMessageDialogComponent, {
        data: {
          case: this.case,
        },
      })
      .afterClosed()
      .pipe(
        filter((d: { message: string; visibleForClient: boolean }) => !!d?.message),
        mergeMap((d) => this.caseService.deny(this.case, d.message, d.visibleForClient)),
      )
      .subscribe({
        next: (c) => {
          this.case = c;
          snack(t('Case declined'));
        },
        error: (err) => snackErr(t('Could not decline case'), err),
      });
  }

  getObjectWithNoParent<T extends { id: number; ParentId?: number | null }>(objs?: T[]): T | null {
    if (!objs?.length) {
      return null;
    }

    return objs.find((o) => o.ParentId === null);
  }

  complete(): void {
    let obs: Observable<Case>;

    const projectObject = this.getObjectWithNoParent(this.case.Objects) as Project;

    if (projectObject?.data?.contractorCompleteMessage) {
      obs = this.dialog
        .open(CaseStatusMessageDialogComponent, {
          data: {
            case: this.case,
            type: 'complete',
          },
        })
        ?.afterClosed()
        ?.pipe(
          filter((d: { message: string; visibleForClient: boolean }) => !!d?.message),
          mergeMap((d) => this.caseService.complete(this.case, d.message, d.visibleForClient)),
        );
    } else {
      obs = this.caseService.complete(this.case);
    }

    obs?.pipe(take(1)).subscribe((c) => {
      this.case = c;
    });
  }

  finish(): void {
    if (!this.case?.Agreement?.id) {
      this.caseService.finish(this.case).subscribe((c) => {
        this.case = c;
      });
    } else {
      if (this.case.Agreement.requireReports && isEmpty(this.agreementReports)) {
        this.dialog.open(WarningDialogComponent);
      } else {
        this.caseService.finish(this.case).subscribe((c) => {
          this.case = c;
        });
      }
    }
  }

  reopen(): void {
    this.caseService.reopen(this.case).subscribe((c) => {
      this.case = c;
    });
  }

  markAsUnread(): void {
    this.caseService.markAsUnread(this.case).subscribe((c) => {
      this.case = c;
      this.markedAsUnread.emit();
    });
  }

  markAsRead(): void {
    this.caseService.markAsRead(this.case).subscribe((c) => {
      this.case = c;
    });
  }

  archive(): void {
    this.caseService.archive(this.case).subscribe((c: Case) => {
      snack(t('Case archived'));
      this.case = c;
    });
  }

  getAccess(): void {
    if (this.case) {
      if (this.profile) {
        this.userCanAcceptOrDecline = this.getUserCanAcceptOrDecline();
        this.userCanComplete = this.getUserCanComplete();
        this.userCanEdit = !!this.case.access?.caseManager || !!this.case?.access?.admin;
        this.userIsContractor = !!this.case.access?.contractor;
      } else {
        this.userService.filteredProfile$.pipe(take(1)).subscribe((profile: User) => {
          this.profile = profile;
          this.getAccess();
        });
      }
    }
  }

  getUserCanAcceptOrDecline(): boolean {
    if (this.case) {
      if (this.case.ContractorId === this.profile?.id) {
        return this.case.CaseStatusId !== 2;
      }

      if (this.case.Contractors?.map((c) => c.id).includes(this.profile.id)) {
        const relation = getUserRelationForCase(this.profile, this.case);

        return relation === 'new' || relation === 'declined';
      }
    }

    return false;
  }

  getUserCanComplete(): boolean {
    if (this.case && this.case.ContractorId === this.profile?.id) {
      return this.case.CaseStatusId === 2;
    }

    const relation = getUserRelationForCase(this.profile, this.case);

    return relation === 'accepted';
  }

  save(): void {
    if (!this.formComponent?.invalid && this.userCanEdit) {
      const sub = this.formComponent.save().subscribe({
        next: (d: Case | RepeatableCase) => {
          snack(t('Case saved'));

          if (d instanceof RepeatableCase) {
            this.case.RepeatableCase = d;
          } else {
            this.case = d;
          }
        },
        error: (err) => {
          if (this?.formComponent) {
            this.formComponent.loading = false;
          }

          snackErr(t('Could not save'), err);
        },
      });

      this.subscription.add(sub);
    }
  }

  datePassed(date: Date): boolean {
    if (!date) {
      return false;
    }

    return moment().isAfter(moment(date));
  }

  dateAble(input: number | string): Date | null {
    let date: Moment;

    if (typeof input === 'number') {
      date = moment.unix(input);
    } else {
      date = moment(input);
    }

    if (date.isValid()) {
      return date.toDate();
    }

    return null;
  }

  isContractor(userId: number): boolean {
    return !!(this.case.ContractorId === userId || this.case.Contractors.find((c) => c.id === userId));
  }

  isUserInList(user: User, users: User[]): boolean {
    return users.map((u) => u.id).indexOf(user.id) !== -1;
  }

  ngOnDestroy(): void {
    this.user$$?.unsubscribe();
    this.getCaseResponse$?.unsubscribe();
    this.subscription.unsubscribe();
  }

  openMarking(): void {
    const data: FileUsageViewerData = {
      fileUsages: this.caseFloorplans,
      mode: FileUsageViewerMode.Mark,
      editable: true,
      client: false, // This is quite bad isn't it
      modelData: {
        model: MarkingModelType.Case,
        modelId: this.case.id,
      },
    };

    this.dialog
      .open(FileUsageViewerDialogComponent, { data })
      .afterClosed()
      .subscribe({
        next: (dd) => {
          if (dd) {
            this.updateMarkings(dd?.markings ?? []);
          }
        },
      });
  }

  async drawOnFloorPlan(fileUsage: FileUsage): Promise<void> {
    const file = await openPinturaEditor(fileUsage.File.signed.url, undefined, fileUsage.fileName);

    if (file) {
      await this.caseFiles.addFiles([file]);
    }
  }

  imageUploaded(event: FileUsage[]): void {
    this.agreementReports = event;
  }
}
