import { Pipe, PipeTransform } from '@angular/core';
import { get, orderBy } from 'lodash';
import { Autocomplete } from '../../models/autocomplete';

type ModelWithParentOrParentId = Autocomplete & { ParentId?: number | string | null };

@Pipe({
  name: 'sortFlatNestedForModelWithParent',
  standalone: true,
})
export class SortFlatNestedForModelWithParentPipe implements PipeTransform {
  transform(collection: ModelWithParentOrParentId[], actualSort = true): ModelWithParentOrParentId[] {
    if (!collection) {
      return [];
    }

    if (!actualSort) {
      return collection;
    }

    return nestCollectionByField(collection, null, 0, UseParentOrParentId.Parent);
  }
}

@Pipe({
  name: 'sortFlatNestedForModelWithParentId',
  standalone: true,
})
export class SortFlatNestedForModelWithParentIdPipe implements PipeTransform {
  transform(collection: ModelWithParentOrParentId[], actualSort = true): ModelWithParentOrParentId[] {
    if (!collection) {
      return [];
    }

    if (!actualSort) {
      return collection;
    }

    return nestCollectionByField(collection, null, 0, UseParentOrParentId.ParentId);
  }
}

export enum UseParentOrParentId {
  Parent = 'Parent',
  ParentId = 'ParentId',
}

function nestCollectionByField(
  collection: ModelWithParentOrParentId[],
  parentId: number | string | null,
  depth: number,
  useParentOrParentId: UseParentOrParentId = UseParentOrParentId.Parent,
): ModelWithParentOrParentId[] {
  const unsortedParents = collection.filter((item) => {
    if (parentId === null) {
      return !item?.ParentId && !item?.Parent;
    }

    const itemParentId = get(item, useParentOrParentId === UseParentOrParentId.Parent ? 'Parent.id' : 'ParentId');

    return itemParentId === parentId;
  });

  const parents = orderBy(unsortedParents, ['name', 'id'], ['asc', 'asc']);

  const returnCollection = [];

  for (const parent of parents) {
    // First add the parent.
    returnCollection.push(parent);

    const nextParentId = get(parent, useParentOrParentId === UseParentOrParentId.Parent ? 'id' : 'ParentId');

    // Then add the children.
    returnCollection.push(...nestCollectionByField(collection, nextParentId, depth, useParentOrParentId));
  }

  // This should be nicely ordered and flat.
  return returnCollection;
}
