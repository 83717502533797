<div class="am mat-elevation-z2">
  <mat-menu #contractorsMenu="matMenu">
    <ng-template
      matMenuContent
      let-f="field">
      <div
        mat-menu-item
        [routerLink]="['/company', c.CustomerId, 'user', c.id]"
        *ngFor="let c of f.Contractors">
        {{ c.name }} ({{ c.Customer?.name }})
      </div>
    </ng-template>
  </mat-menu>

  <mat-button-toggle-group
    multiple
    class="aml amt max-width-fit-content">
    <mat-button-toggle
      value="Has Contractor"
      (click)="updateUrlParams()"
      [checked]="showWithContractors">
      <T str="Has Contractor"></T>
    </mat-button-toggle>

    <mat-button-toggle
      value="No Contractor"
      (click)="updateUrlParamsWithout()"
      [checked]="showWithoutContractors">
      <T str="No Contractor"></T>
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div class="display-grid-cards am">
    <ng-container *ngFor="let f of filteredFields">
      <div class="df c mat-elevation-z2">
        <div class="ap df c f1">
          <h2 class="rmv">{{ f.fullName }}</h2>
          <div class="amts mat-caption">{{ f.description }}</div>
        </div>
        <div class="actions aps df ac jsb">
          <a
            mat-button
            color="primary"
            [routerLink]="[f.id]">
            <T
              str="Edit"
              context="action"
              _context="action">
            </T>
          </a>

          <div class="df ac amrs">
            <apex-color-picker
              [(ngModel)]="f.actualColor"
              (ngModelChange)="colorChange(f)"></apex-color-picker>
            <button
              mat-icon-button
              [disabled]="!f.Contractors?.length"
              [matMenuTriggerFor]="contractorsMenu"
              [matMenuTriggerData]="{ field: f }"
              (click)="$event.stopPropagation(); $event.preventDefault()"
              (mousedown)="$event.stopPropagation()">
              <mat-icon
                [matBadge]="f.Contractors?.length"
                [matBadgeHidden]="!f.Contractors?.length">
                person
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="!fields?.length || (!showWithContractors && !showWithoutContractors)"
    class="f1 df ac jc am">
    <T str="No disciplines"></T>
  </div>
  <button
    mat-fab
    #fab
    routerLink="add"
    [matTooltip]="'Add disciplines' | translate"
    (click)="fab.disabled = true">
    <mat-icon>add</mat-icon>
  </button>
</div>
