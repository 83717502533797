<h2 class="am">
  <ng-container *ngIf="!objectField.id">
    <T str="New discipline"></T>
  </ng-container>
  <ng-container *ngIf="objectField.id">
    {{ objectField.fullName }}
  </ng-container>
</h2>

<form
  #form="ngForm"
  class="mat-elevation-z2 am ap df c">
  <apex-autocomplete
    class="aamhs"
    type="Field"
    required
    [label]="'Discipline' | translate"
    name="field"
    [disabled]="!!objectField.id"
    [(ngModel)]="objectField.FieldId"
    (modelChange)="baseFieldChange($event)"
    [params]="{
      ObjectId: objectField.ObjectId,
    }"></apex-autocomplete>
  <div class="df p-c">
    <mat-form-field class="f1 amr p-rm">
      <mat-label>
        <T str="Name"></T>
      </mat-label>
      <span matTextPrefix="">{{ objectField.basePrefix }}&nbsp;</span>
      <input
        matInput
        name="name"
        [(ngModel)]="objectField.name" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>
        <T str="Color"></T>
      </mat-label>
      <input
        matInput
        type="text"
        name="color"
        [(ngModel)]="objectField.actualColor"
        [pattern]="hexColorPattern" />
      <apex-color-picker
        matIconSuffix
        name="colorPicker"
        [(ngModel)]="objectField.actualColor">
      </apex-color-picker>
    </mat-form-field>
  </div>
  <mat-form-field>
    <mat-label>
      <T str="Description"></T>
    </mat-label>
    <input
      matInput
      name="description"
      [(ngModel)]="objectField.description" />
  </mat-form-field>
  <div class="amb">
    <h3 class="amvs">
      <T str="Contractors"></T>
    </h3>

    <apex-autocomplete
      #contractorAuto
      type="User"
      [label]="'New contractor' | translate"
      name="contractor"
      (modelChange)="addContractor($event)">
    </apex-autocomplete>

    <div
      *ngFor="let c of objectField.Contractors"
      class="df ac">
      <div class="f1">{{ c.name }} ({{ c.Customer?.name }})</div>
      <button
        mat-icon-button
        (click)="removeContractor(c)">
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
  <div class="df jsb ac">
    <div>
      <button
        mat-raised-button
        type="button"
        color="warn"
        [disabled]="!objectField?.id"
        (click)="deleteField()"
        class="amr">
        <T str="Delete"></T>
      </button>
    </div>
    <div class="df">
      <button
        mat-raised-button
        type="button"
        (click)="back()"
        class="amr">
        <T str="Back"></T>
      </button>
      <button
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="form.invalid"
        (click)="saveField()">
        <T str="Save"></T>
      </button>
    </div>
  </div>
</form>
