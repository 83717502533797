import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '../translate/translate.module';
import { UserFavoriteButtonModule } from '../user-favorite-button/user-favorite-button.module';
import { AutocompleteComponent } from './autocomplete.component';
import { AutocompleteService } from './autocomplete.service';
import { AutocompleteTextComponent } from './text/text.component';
import { AutocompleteViewerComponent } from './viewer/viewer.component';

import { SelectOnFocusModule } from '../../directives/select-on-focus/select-on-focus.module';
import {
  DistanceToTopInCollectionPipe,
  DistanceToTopInCollectionPipeWithParent,
} from '../../pipes/distance-to-top-in-collection/distance-to-top-in-collection.pipe';
import { SortFlatNestedForModelWithParentPipe } from '../../pipes/sort-flat-nested/sort-flat-nested.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatInputModule,
    TranslateModule,
    UserFavoriteButtonModule,
    SelectOnFocusModule,
    DistanceToTopInCollectionPipe,
    DistanceToTopInCollectionPipeWithParent,
    SortFlatNestedForModelWithParentPipe,
  ],
  exports: [AutocompleteComponent, AutocompleteViewerComponent, AutocompleteTextComponent],
  declarations: [AutocompleteComponent, AutocompleteViewerComponent, AutocompleteTextComponent],
  providers: [AutocompleteService],
})
export class AutocompleteModule {}
