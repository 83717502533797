<h2 class="am">
  <ng-container *ngIf="!agreement.id">
    <T str="New Agreement"></T>
  </ng-container>
  <ng-container *ngIf="agreement.id">
    {{ agreement.name }}
  </ng-container>
</h2>

<form
  (ngSubmit)="save()"
  #agreementForm="ngForm"
  class="df c apl apt am mat-elevation-z2">
  <mat-form-field class="amr">
    <input
      matInput
      type="text"
      name="name"
      [(ngModel)]="agreement.name"
      [placeholder]="'Name' | translate"
      required />
  </mat-form-field>

  <mat-form-field class="amr">
    <textarea
      matInput
      name="description"
      cdkTextareaAutosize
      cdkAutosizeMinRows="1"
      cdkAutosizeMaxRows="10"
      [placeholder]="'Description' | translate"
      [(ngModel)]="agreement.description">
    </textarea>
  </mat-form-field>

  <apex-object-selector
    class="amr"
    name="object"
    [label]="'Object' | translate"
    [meta]="MetaType.Commercial"
    [(ngModel)]="agreement.ObjectId"
    [queryParams]="{ ParentId: projectId }"
    required>
  </apex-object-selector>

  <div class="df ac">
    <mat-form-field class="f1 amr">
      <mat-label>
        <T str="Period"></T>
      </mat-label>
      <mat-date-range-input
        [rangePicker]="picker"
        required>
        <input
          matStartDate
          name="from"
          [(ngModel)]="agreement.from"
          [placeholder]="'Start date' | translate"
          required />
        <input
          matEndDate
          name="to"
          [(ngModel)]="agreement.to"
          [placeholder]="'End date' | translate" />
      </mat-date-range-input>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="picker">
      </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-checkbox
      class="amr"
      name="active"
      [(ngModel)]="agreement.active">
      <T str="Active"></T>
    </mat-checkbox>
  </div>

  <mat-checkbox
    class="amr amb"
    name="active"
    [(ngModel)]="agreement.requireReports">
    <T str="Require an uploaded report to close the case"></T>
  </mat-checkbox>

  <div class="df f1 ac jsb w">
    <div class="df ac amr amb p-f1">
      <div class="amr">
        <button
          mat-raised-button
          type="button"
          color="warn"
          class="p-f1"
          [disabled]="!agreement.id"
          (click)="delete()">
          <T str="Delete"></T>
        </button>
      </div>
    </div>
    <div class="df ac p-f1">
      <div class="amr amb p-df p-f1">
        <button
          mat-raised-button
          type="button"
          class="p-f1"
          apexHistoryBack>
          <T str="Back"></T>
        </button>
      </div>
      <div class="amr amb p-df p-f1">
        <button
          mat-raised-button
          type="submit"
          color="primary"
          class="p-f1"
          [disabled]="!agreementForm.valid">
          <T str="Save"></T>
        </button>
      </div>
    </div>
  </div>
</form>

<div class="df w aml">
  <!-- left -->
  <div class="df c f1 amr">
    <apex-file-usage
      #fileUsage
      class="amb"
      view="List"
      [title]="'Files' | translate"
      self="agreement"
      [selfId]="agreement.id || 0"
      name="files">
    </apex-file-usage>

    <apex-file-usage
      #fileUsagePublic
      class="amb"
      view="List"
      [title]="'Public Files' | translate"
      [description]="'Will be shared with clients' | translate"
      self="agreement"
      [selfId]="agreement.id || 0"
      name="public-files">
    </apex-file-usage>

    <div
      class="amb"
      *ngIf="agreement.id">
      <apex-model-role
        model="agreement"
        [modelId]="agreement.id"
        role="involved"
        [label]="'Involved' | translate"
        [title]="'Involved' | translate">
      </apex-model-role>
    </div>
  </div>

  <!-- right -->
  <div
    class="df c f1 amr"
    *ngIf="agreement.id">
    <apex-text-usage
      class="amb"
      [label]="'External Information' | translate"
      model="agreement"
      [modelId]="agreement.id"
      name="external-information">
    </apex-text-usage>

    <apex-text-usage
      class="amb"
      [label]="'Internal Information' | translate"
      model="agreement"
      [modelId]="agreement.id"
      name="internal-information">
    </apex-text-usage>

    <apex-object-fields></apex-object-fields>
  </div>
</div>
