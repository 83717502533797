import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FieldsResolver } from '../../../field/field.resolve';
import { AddObjectFieldsComponent } from './add.component';
import { ObjectFieldComponent } from './form.component';
import { ObjectFieldsComponent } from './list.component';
import { ObjectFieldResolver, ObjectFieldsResolver } from './object-field.resolver';

export const routes: Routes = [
  {
    path: '',
    component: ObjectFieldsComponent,
    resolve: {
      objectFields: ObjectFieldsResolver,
    },
  },
  {
    path: 'new',
    component: ObjectFieldComponent,
    resolve: {
      objectField: ObjectFieldResolver,
    },
  },
  {
    path: 'add',
    component: AddObjectFieldsComponent,
    resolve: {
      objectFields: ObjectFieldsResolver,
      fields: FieldsResolver,
    },
  },
  {
    path: ':fid',
    component: ObjectFieldComponent,
    resolve: {
      objectField: ObjectFieldResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class ObjectFieldRoutingModule {}
