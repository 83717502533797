import { CdkDrag, CdkDragHandle, CdkDragPlaceholder } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ResponseSidebarItemPinnedDto, SidebarModel } from '../../../../../../generated/apex-rest';
import { FilterSidebarItemsPipe } from '../sidebar/pipes/filter-sidebar-items.pipe';
import { SidebarItemAccessPipe } from '../sidebar/pipes/sidebar-item-access.pipe';
import { SidebarService } from '../sidebar/services/sidebar.service';
import { SidebarItem } from '../sidebar/sidebar-items.types';
import { TranslateModule } from '../translate/translate.module';
import { StringToRouterLinkPipe } from './string-to-router-link.pipe';
import { StringToQueryParamsPipe } from './string-to-router-query-params.pipe';

@Component({
  selector: 'apex-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrls: ['./sidebar-item.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SidebarItemAccessPipe,
    FilterSidebarItemsPipe,
    MatButtonModule,
    MatTooltipModule,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    CdkDragHandle,
    StringToRouterLinkPipe,
    StringToQueryParamsPipe,
    CdkDrag,
    CdkDragPlaceholder,
  ],
  providers: [],
})
export class SidebarItemComponent {
  @Input() sidebarItem: SidebarItem;
  @Input() pinned = false;
  @Input() responseSidebarItemPinned: ResponseSidebarItemPinnedDto[];
  @Input() showPinButtons = true;

  constructor(protected sidebarService: SidebarService) {}

  async pinSidebarItem(sidebarItem: SidebarItem): Promise<void> {
    await this.sidebarService.create(sidebarItem.routerLink, SidebarModel.Sidebar);
    await this.sidebarService.readCollection();
  }

  async deletePinnedSidebarItem(sidebarItem: SidebarItem): Promise<void> {
    if (!this.responseSidebarItemPinned) {
      return;
    }

    const id = this.responseSidebarItemPinned.find((sidebarItemPin: ResponseSidebarItemPinnedDto) => {
      if (Number.isNaN(Number(sidebarItemPin.modelId))) {
        return sidebarItemPin.modelId === sidebarItem.routerLink && !sidebarItem.modelId;
      }

      return sidebarItemPin.modelId === sidebarItem.modelId;
    })?.id;

    if (!id) {
      return;
    }

    await this.sidebarService.delete(id);
    await this.sidebarService.readCollection();
  }
}
