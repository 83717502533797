import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Autocomplete } from '../../models/autocomplete';
import { AutocompleteComponent } from '../autocomplete/autocomplete.component';
import { AutocompleteString } from '../autocomplete/autocomplete.types';
import { t } from '../translate/translate.function';

@Component({
  selector: 'apex-autocomplete-picker',
  templateUrl: './picker.component.html',
})
export class AutocompletePickerComponent {
  @Input() params: Record<string, string | number | boolean> = {};
  @Input() multiple = false;
  @Input() excludeIds: number[] = [];

  @Input() label = t('User');
  @Input() type: AutocompleteString = 'User';

  @ViewChild(AutocompleteComponent) autocomplete: AutocompleteComponent;

  @Output() optionAdd = new EventEmitter<Autocomplete>();
  @Output() optionRemoved = new EventEmitter<Autocomplete>();

  picked: Autocomplete[] = [];

  get excludedIds(): number[] {
    const excluded = this.excludeIds ?? [];
    const picked = this.picked ?? [];

    return [...excluded, ...picked.filter((p) => !!p?.id).map((p) => p.id)];
  }

  modelChange(autocomplete: Autocomplete): void {
    if (!this.multiple) {
      this.picked = [autocomplete];
    }

    if (autocomplete?.id && this.multiple) {
      this.picked.push(autocomplete);

      this.autocomplete.writeValue(null);
      this.autocomplete.blur();
    }

    this.optionAdd.emit(autocomplete);
  }

  remove(autocomplete: Autocomplete): void {
    const idx = this.picked.indexOf(autocomplete);

    if (idx !== -1) {
      this.picked.splice(idx, 1);
    }

    this.optionRemoved.emit(autocomplete);
  }
}
