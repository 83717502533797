import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { TranslateModule } from '../translate/translate.module';

@Component({
  selector: 'apex-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatButton,
    TranslateModule,
    MatDialogClose,
    MatIcon,
    MatIconButton,
  ],
})
export class WarningDialogComponent {
  constructor(public dialogRef: MatDialogRef<WarningDialogComponent>) {}
}
