<mat-form-field>
  <mat-label>
    {{ label }}
  </mat-label>
  <input
    #input
    matInput
    [matAutocomplete]="auto"
    [formControl]="innerFormControl"
    [errorStateMatcher]="matcher"
    #autocomplete="matAutocompleteTrigger"
    [required]="required"
    [placeholder]="placeholder"
    apexSelectOnFocus
    (focus)="queryRequest$.next(model)" />
  <mat-hint *ngIf="hint && showHint">
    {{ hint }}
  </mat-hint>
  <mat-autocomplete
    #auto="matAutocomplete"
    [displayWith]="displayModel.bind(this)">
    <mat-option
      *ngIf="isLoading"
      disabled>
      <mat-spinner diameter="24"></mat-spinner>
    </mat-option>
    <mat-option
      *ngIf="!isLoading && !models?.length"
      disabled>
      <T
        str="No matches found"
        *ngIf="model || !favoriteAvailable"></T>
      <T
        str="No favorites saved"
        *ngIf="!(model || !favoriteAvailable)"></T>
    </mat-option>
    <ng-container *ngIf="!isLoading">
      <mat-option
        *ngFor="let model of models | sortFlatNestedForModelWithParent: type === 'addon-category'"
        [value]="model"
        [title]="getModelName(model)"
        [style.padding-left.px]="((model | distanceToTopInCollectionWithParent: models) + 1) * 16"
        class="df autocomplete-option">
        <div class="w-100 h-100 df ac">
          <div class="f1 truncate">
            {{ model?.name }}
            <div
              class="mat-caption"
              *ngIf="model?.Parent">
              {{ model.Parent.name }}
              <ng-container *ngIf="model?.Parent?.Parent"> / {{ model.Parent.Parent.name }} </ng-container>
            </div>

            <div
              class="mat-caption"
              *ngIf="type === 'casecategory' && hasProject && projectClientCategories.includes(model.id)">
              <T str="Visible for client"></T>
            </div>
          </div>
          <apex-user-favorite-button
            class="position-absolute right-0"
            *ngIf="favoriteAvailable"
            [modelName]="type"
            [modelId]="model.id">
          </apex-user-favorite-button>
        </div>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
  <!-- <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar> -->
</mat-form-field>
