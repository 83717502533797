<apex-autocomplete
  #autocomplete
  [type]="type"
  [label]="label"
  [params]="params"
  [excludeIds]="excludedIds"
  (modelChange)="modelChange($event)">
</apex-autocomplete>

<ng-container *ngIf="multiple">
  <div class="df c amt">
    <mat-chip-listbox class="f1">
      <mat-chip-option
        *ngFor="let p of picked"
        [removable]="true"
        (removed)="remove(p)">
        {{ p.name }}
        <ng-container *ngIf="p.Parent?.name"> ({{ p.Parent.name }}) </ng-container>
        <mat-icon matChipRemove>clear</mat-icon>
      </mat-chip-option>
    </mat-chip-listbox>
  </div>
  <div class="amt mat-caption">
    <T
      str="{count} selected"
      [vars]="{
        count: picked?.length ?? 0,
      }"></T>
  </div>
</ng-container>
