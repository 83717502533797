import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep, isEqual } from 'lodash-es';
import { Moment, isMoment } from 'moment/moment';
import { Case } from 'projects/apex/src/app/models/case';
import { transformQueryParams } from 'projects/apex/src/app/utils/functions';
import { Observable, Subject, Subscription, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { FileUsageViewerMode } from '../../../components/file-usage-viewer/file-usage-viewer.types';
import { FileUsageService } from '../../../components/file-usage/file-usage.service';
import { Apartment } from '../../../models/apartment';
import { CaseParams } from '../../../models/case-params';
import { FileUsage } from '../../../models/file-usage';
import { Marking } from '../../../models/marking';
import { constants } from '../../../utils/constants';
import { ApartmentService } from '../../apartment/apartment.service';
import { ObjectService } from '../../object/object.service';
import { CaseService } from '../case.service';
import { CaseFormDialogComponent } from '../form/dialog.component';
import { CaseListComponent } from '../list/list.component';
import { MarkingDialogComponent } from '../marking/dialog/marking-dialog.component';
import MarkingDialogData from '../marking/dialog/marking-dialog.types';
import { CaseMarkingComponent } from '../marking/marking.component';
import { CaseViewComponent } from '../view/view.component';

export const casesTabIndexes = {
  list: 0,
  marking: 1,
  calendar: 2,
};

@Component({
  selector: 'apex-cases',
  templateUrl: './cases.component.html',
})
export class CasesComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() standardParams: CaseParams = { showOpen: true } as CaseParams;
  @Input() queryParams: CaseParams = this.standardParams;
  @Input() highlightedCaseIds: number[] = [];
  @Input() listFullSize: boolean;
  @Input() dialogClass: string;
  @Input() showCalendar = true;
  @Input() newCaseValues: Partial<Case>;
  @Input() customAdd: (newCase?: Case) => Observable<Case>;
  @Input() showFab = true;
  @Input() isTakeover = false;

  @Output() casesChange = new EventEmitter<Case[]>();
  @Output() markingChange = new EventEmitter<Marking>();
  @Output() caseChange = new EventEmitter<Case>();

  @ViewChild('marking') marking: CaseMarkingComponent;
  @ViewChild('view') view: CaseViewComponent;
  @ViewChild('list') list: CaseListComponent;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;

  public floorplans: FileUsage[];
  public markingCount: number;
  public id: number;
  public count = 0;
  public cases: Case[];
  public page = 0;

  public casesLoading = true;
  public countLoading = true;
  public floorplansLoading = true;

  public fragmentEnabled = false;
  public lastFragment: string;

  public mobile = false;

  fragments = casesTabIndexes;

  getCasesRequest$ = new Subject<CaseParams>();
  getCasesResponse$: Observable<Case[]> = this.getCasesRequest$.pipe(
    switchMap((params: CaseParams) => {
      this.page = 0;

      return this.caseService.getCases(params).pipe(catchError(() => of([])));
    }),
  );

  getMoreCasesRequest$ = new Subject<CaseParams>();
  getMoreCasesResponse$: Observable<Case[]> = this.getMoreCasesRequest$.pipe(
    switchMap((params: CaseParams) => this.caseService.getCases(params).pipe(catchError(() => of([])))),
  );

  getFloorplanRequest$ = new Subject<CaseParams>();
  getFloorplanResponse$: Observable<FileUsage[]> = this.getFloorplanRequest$.pipe(
    switchMap(() => this.getFloorplansFromQueryParams(this.queryParams).pipe(catchError(() => of([])))),
  );

  getCountRequest$ = new Subject<CaseParams>();
  getCountResponse$: Observable<{ count: number }> = this.getCountRequest$.pipe(
    switchMap((params: CaseParams) => this.caseService.getCaseCount(params).pipe(catchError(() => of({ count: 0 })))),
  );

  private subs: Subscription[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private caseService: CaseService,
    private fileUsageService: FileUsageService,
    private apartmentService: ApartmentService,
    private objectService: ObjectService,
  ) {}

  ngOnInit(): void {
    this.mobile = window.innerWidth < constants.breakpoints.desktopMin;
    this.subs = [
      this.route.fragment.subscribe({
        next: (fragment: string) => {
          this.applyFragment(fragment);
        },
      }),
      this.getCasesResponse$.subscribe({
        next: (cases: Case[]) => {
          this.cases = cases ?? [];
          this.casesChange.emit(this.cases);
          this.getFloorplanRequest$.next(null);
          this.casesLoading = false;
          this.getMarkingCount();
        },
      }),
      this.getMoreCasesResponse$.subscribe({
        next: (cases: Case[]) => {
          this.cases = this.cases.concat(cases);
          this.casesChange.emit(this.cases);
          this.getFloorplanRequest$.next(null);
          this.casesLoading = false;
          this.getMarkingCount();
        },
      }),
      this.getCountResponse$.subscribe({
        next: (res) => {
          this.count = res?.count;
          this.countLoading = false;
        },
      }),
      this.getFloorplanResponse$.subscribe({
        next: (floorplans: FileUsage[]) => {
          this.floorplans = floorplans;
          this.floorplansLoading = false;
        },
      }),
      this.route.queryParams.subscribe({
        next: (queryParams) => {
          const cp = transformQueryParams({
            ...this.standardParams,
            ...this.caseService.removeOtherParams(queryParams),
          });

          if (!this.cases || !isEqual(this.queryParams, cp)) {
            this.queryParams = cp;
            this.refresh();
          }
        },
      }),
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.queryParams && !changes.queryParams.firstChange) {
      this.refresh();
    }

    if (changes.cases && this.cases) {
      this.casesChange.emit(this.cases as Case[]);
    }
  }

  ngAfterViewInit(): void {
    if (this.route.snapshot.fragment) {
      this.applyFragment(this.route.snapshot.fragment);
    }

    this.fragmentEnabled = true;

    const params = this.route?.snapshot?.queryParams;
    const queryParamsOverrides = {};

    if (params.ProjectId) {
      Object.assign(queryParamsOverrides, {
        ProjectId: null,
        Project: params.ProjectId,
      });
    }

    if (params.ApartmentId) {
      Object.assign(queryParamsOverrides, {
        ApartmentId: null,
        Apartment: params.ApartmentId,
      });
    }

    if (params.ClientId) {
      Object.assign(queryParamsOverrides, {
        ClientId: null,
        Client: params.ClientId,
      });
    }

    if (params.CategoryId) {
      Object.assign(queryParamsOverrides, {
        CategoryId: null,
        Category: params.CategoryId,
      });
    }

    if (Object.keys(queryParamsOverrides).length) {
      void this.router
        .navigate([], {
          queryParams: queryParamsOverrides,
          queryParamsHandling: 'merge',
          state: { skipScroll: true },
        })
        .then(() => {
          if (params.hasOwnProperty('newCase')) {
            setTimeout(() => {
              this.newCase();
            });
          }
        });
    } else if (params.hasOwnProperty('newCase')) {
      this.newCase();
    }
  }

  openDialog(): void {
    this.dialog.open<MarkingDialogComponent, MarkingDialogData>(MarkingDialogComponent, {
      data: {
        mode: FileUsageViewerMode.Mark,
        cases: this.cases,
        floorPlans: this.floorplans,
        highlightedCaseIds: this.highlightedCaseIds,
        loading: this.floorplansLoading,
        editable: false,
        saveDisabled: false,
        fullscreen: true,
        id: this.id,
        floorplansLoading: this.floorplansLoading,
        view: this.view,
        updateCase: (event: Case) => this.updateCase(event),
        casesChange: this.casesChange,
        newCaseFromMarking: (event: { marking: Marking; fileUsage: FileUsage }) => this.newCaseFromMarking(event),
      },
      panelClass: ['apex-fullscreen-dialog', 'phone'],
      width: '100%',
      height: '100%',
      maxWidth: '100%',
    });
  }

  applyFragment(fragment: string): void {
    const fragments = fragment?.split('-');

    if (fragments?.length) {
      const fragmentIndex = this.fragments[fragments[0]];

      if (this.tabGroup) {
        this.tabGroup.selectedIndex = fragmentIndex;
      }

      const fragmentId = Number(fragments[1]);

      switch (fragmentIndex) {
        case this.fragments.list:
          this.id = fragmentId && Number.isInteger(fragmentId) ? fragmentId : null;

          if (this.list && this.list.id !== this.id) {
            this.list.id = this.id;
          }

          break;

        case this.fragments.marking:
          if (this.marking) {
            this.marking.selectedIndex = fragmentId && Number.isInteger(fragmentId) ? fragmentId : 0;
            this.marking.updateFileUsageViewerData();
          }

          break;
      }
    } else {
      this.id = null;
    }
  }

  setFragment(index: number, id?: number): void {
    if (this.fragmentEnabled) {
      const fragment = Object.keys(this.fragments).find((key) => this.fragments[key] === index);

      void this.router.navigate([], {
        fragment: id ? `${fragment}-${id}` : fragment,
        queryParamsHandling: 'preserve',
      });
    }
  }

  refresh(): void {
    this.casesLoading = true;
    this.countLoading = true;
    this.floorplansLoading = true;
    this.cases = [];
    this.floorplans = [];
    this.getCasesRequest$.next(this.queryParams);
    this.getCountRequest$.next(this.queryParams);
  }

  getMarkingCount(): void {
    this.markingCount = [].concat(
      ...this.cases.filter((c) => !c.archivedAt && c.Markings?.length).map((c) => c.Markings),
    )?.length;
  }

  getFloorplansFromQueryParams(p: CaseParams): Observable<FileUsage[]> {
    if (p?.Project || p?.Apartment || p?.Object) {
      return forkJoin(
        [].concat(
          (p.Project ? (Array.isArray(p.Project) ? p.Project : [p.Project]) : []).map((id: number) =>
            this.fileUsageService.all('project', id, 'floorplans'),
          ),
          (p.Apartment ? (Array.isArray(p.Apartment) ? p.Apartment : [p.Apartment]) : []).map((id: number) =>
            this.fileUsageService.all('apartment', id, 'floorplans'),
          ),
          (p.Object ? (Array.isArray(p.Object) ? p.Object : [p.Object]) : []).map((id: number) =>
            this.objectService.getFloorplansForParentsAndChildren(id),
          ),
        ),
      ).pipe(map((res) => [].concat(...res).filter((floorplan: FileUsage) => floorplan)));
    } else {
      return of([]);
    }
  }

  loadMoreCases(): void {
    if (this.count > this.cases.length) {
      this.casesLoading = true;
      this.page++;
      this.getMoreCasesRequest$.next(Object.assign({ page: this.page }, this.queryParams));
    }
  }

  newCase(newCase?: Case, preventNavigation = false): void {
    if (!preventNavigation) {
      void this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { newCase: true },
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });
    }

    let startCase = newCase ? newCase : this.caseService.newCaseFromParams(this.queryParams);

    if (this.newCaseValues) {
      startCase = {
        ...startCase,
        ...this.newCaseValues,
      };
    }

    if (this.customAdd) {
      this.customAdd(startCase)
        .pipe(take(1))
        .subscribe({
          next: (c) => {
            if (c?.id) {
              this.addCaseToList(c);
            }
          },
        });

      return;
    }

    const ref = this.dialog.open(CaseFormDialogComponent, {
      data: {
        case: startCase,
      },
      panelClass: this.dialogClass,
    });

    const subs = [
      ref.componentInstance.saveAndContinueExecuted.subscribe({
        next: (c: Case) => {
          this.addCaseToList(c);
        },
      }),
      ref.afterClosed().subscribe({
        next: (c: Case) => {
          if (c?.id) {
            this.id = c.id;
            this.addCaseToList(c);
          }

          this.marking?.refreshFloorplans();
          subs.forEach((s) => s?.unsubscribe());

          if (!preventNavigation) {
            void this.router.navigate([], {
              relativeTo: this.route,
              queryParams: { newCase: null },
              queryParamsHandling: 'merge', // remove to replace all query params by provided
            });
          }
        },
      }),
    ];
  }

  newCaseFromMarking(event: { marking: Marking; fileUsage: FileUsage }): void {
    if (!event.marking.id && !event.marking.modelId) {
      const newCase = this.queryParams ? this.caseService.newCaseFromParams(this.queryParams) : new Case();

      newCase.Markings = [cloneDeep(event.marking)];

      const idx = event.fileUsage.Markings.indexOf(event.marking);

      if (idx !== -1) {
        event.fileUsage.Markings.splice(idx, 1);
      }

      new Observable((observable) => {
        switch (event.fileUsage.self) {
          case 'project':
            newCase.ProjectId = Number(event.fileUsage.selfId);

            observable.next(null);
            observable.complete();
            break;

          case 'apartment':
            newCase.ApartmentId = Number(event.fileUsage.selfId);

            this.apartmentService.get(newCase.ApartmentId).subscribe({
              next: (a: Apartment) => {
                newCase.ProjectId = a.ProjectId;
                observable.next(null);
                observable.complete();
              },
            });
            break;

          case 'object':
            newCase.ObjectId = Number(event.fileUsage.selfId);

            observable.next(null);
            observable.complete();
            break;
        }
      }).subscribe(() => this.newCase(newCase));
    } else if (!event.marking.id && event.marking.modelId) {
      this.markingChange.emit(event.marking);
    } else if (event.marking.id && event.marking.modelId === this.id && this.view?.case) {
      this.view.setCase(event.marking.modelId);
    }
  }

  addCaseToList(c: Case): void {
    this.cases.unshift(c);
    this.count++;
    this.casesChange.emit(this.cases);
  }

  updateCase(updatedCase: Case): void {
    if (!updatedCase) {
      return;
    }

    let caseToUpdate = this.cases.find((c) => c.id === updatedCase.id);

    if (updatedCase.archivedAt) {
      this.list.id = null;

      if (!this.queryParams?.showArchived || !this.queryParams?.showCompleted) {
        const idx = this.cases.indexOf(caseToUpdate);

        if (idx !== -1) {
          this.cases.splice(idx, 1);
        }

        caseToUpdate = null;
      }
    }

    if (caseToUpdate) {
      Object.assign(caseToUpdate, cloneDeep(updatedCase));
      this.caseChange.emit(caseToUpdate);
      this.getMarkingCount();
      this.marking?.refreshFloorplans();
    }
  }

  isSelectedCaseHighlighted(): boolean {
    return !!this.highlightedCaseIds.find((hcid) => hcid === this.id);
  }

  updateQueryParams(key: string, values: number | number[] | boolean | Moment | string): void {
    if (isMoment(values)) {
      values = values.valueOf() / 1000;
    }

    void this.router.navigate([], {
      queryParams: { [key]: values },
      queryParamsHandling: 'merge',
      state: { skipScroll: true },
    });
  }

  ngOnDestroy(): void {
    this.subs?.forEach((s) => s?.unsubscribe());
  }
}
