/* eslint-disable @typescript-eslint/naming-convention */

import { t as actualT } from '../../components/translate/translate.function';

/** We do not actually want to translate here, but we do want Transifex to pick it up */
const t = (input: string, _vars?: object): string => input;

export const translateSystemLogParams = (vars: object): object => {
  Object.keys(vars).forEach((k) => {
    if (!k.startsWith('_')) {
      vars[k] = actualT(vars[k]);
    }
  });

  return vars;
};

export type SystemLogMessage = keyof typeof SystemLogMessages;

export const SystemLogMessages = {
  CASE_FILEUSAGE_CREATE: t('File {fileName} ({fileId}) added', {
    _context: 'system-log-message',
  }),
  CASE_FILEUSAGE_DESTROY: t('File {fileName} ({fileId}) removed', {
    _context: 'system-log-message',
  }),

  CASE_CONTRACTOR_ADD: t('Contractor {contractorName} added', {
    _context: 'system-log-message',
  }),
  CASE_CONTRACTOR_REMOVE: t('Contractor {contractorName} removed', {
    _context: 'system-log-message',
  }),
  CASE_CONTRACTOR_DENY: t('Contractor did not accept case', {
    _context: 'system-log-message',
  }),
  CASE_CONTRACTOR_ACCECPT: t('Contractor has been added to case', {
    _context: 'system-log-message',
  }),
  CASE_CONTRACTOR_ACCEPT: t('Contractor has been added to case', {
    _context: 'system-log-message',
  }),
  CASE_CONTRACTOR_FINISH: t('Contractor has finished', {
    _context: 'system-log-message',
  }),

  CASE_STATUS_COMPLETE: t('Case completed', {
    _context: 'system-log-message',
  }),
  CASE_STATUS_REOPEN: t('Case reopened', {
    _context: 'system-log-message',
  }),
  CASE_STATUS_CREATE: t('Case created', {
    _context: 'system-log-message',
  }),

  CASE_EDIT_PROPERTY_CHANGE: t('{property} changed from {previous} to {current}', {
    _context: 'system-log-message',
  }),
  CASE_EDIT_PROPERTY_SET: t('{property} set to {current}', {
    _context: 'system-log-message',
  }),
  CASE_EDIT_PROPERTY_DEL: t('{property} {previous} removed', {
    _context: 'system-log-message',
  }),
  CASE_EDIT_PROPERTY_UNKNOWN: t('{property} changed', {
    _context: 'system-log-message',
  }),

  TARGETMESSAGE_NEW_CASE_DEFAULT: t(
    '{sendType} about new case ({id}) sent to {recipientRole} {recipientName} ({recipientCustomerName})',
    {
      _context: 'system-log-message',
    },
  ),
  TARGETMESSAGE_NEW_CASE_CLIENT: t('{sendType} about new case ({id}) sent to {recipientRole} {recipientName}', {
    _context: 'system-log-message',
  }),
  TARGETMESSAGE_NEW_MESSAGE_DEFAULT: t(
    'Message ({id}) sent to {recipientRole} {recipientName} ({recipientCustomerName}) by {sendType}',
    {
      _context: 'system-log-message',
    },
  ),
  TARGETMESSAGE_NEW_MESSAGE_CLIENT: t('Message ({id}) sent to {recipientRole} {recipientName} by {sendType}', {
    _context: 'system-log-message',
  }),

  TARGETMESSAGE_NEW_MESSAGE_EMAIL_ERROR: t(
    'Email for new message ({id}) could not be sent to {recipientName}, invalid email ({recipientEmail})',
    {
      _context: 'system-log-message',
    },
  ),
  TARGETMESSAGE_NEW_CASE_EMAIL_ERROR: t(
    'Email for new case ({id}) could not be sent to {recipientName}, invalid email ({recipientEmail})',
    {
      _context: 'system-log-message',
    },
  ),
  TARGETMESSAGE_NEW_MESSAGE_SMS_ERROR: t(
    'SMS for new message ({id}) could not be sent to {recipientName}, invalid mobile ({recipientMobile})',
    {
      _context: 'system-log-message',
    },
  ),
  TARGETMESSAGE_NEW_CASE_SMS_ERROR: t(
    'SMS for new case ({id}) could not be sent to {recipientName}, invalid mobile ({recipientMobile})',
    {
      _context: 'system-log-message',
    },
  ),

  ENTRY_CARD_REQUEST_CASE_NAME: t('Entry card request for {entryCardName}', {
    _context: 'system-log-message',
  }),

  // I do not know where this last one is supposedly used :(
  UNKNOWN_AND_OLD_SHOULD_DELETE_1: t('%s %s added', {
    _context: 'system-log-message',
  }),

  UNKNOWN: '', // In case we need it.
};
