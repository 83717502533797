import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { AutocompleteModule } from 'projects/apex/src/app/components/autocomplete/autocomplete.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { ObjectFieldComponent } from './form.component';
import { ObjectFieldsComponent } from './list.component';
import { ObjectFieldRoutingModule } from './object-field-routing.module';
import { ObjectFieldService } from './object-field.service';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorPickerModule } from 'projects/apex/src/app/components/color-picker/color-picker.module';
import { FieldService } from '../../../field/field.service';
import { AddObjectFieldsComponent } from './add.component';

const components = [ObjectFieldsComponent, ObjectFieldComponent, AddObjectFieldsComponent];

@NgModule({
  imports: [
    CommonModule,
    ObjectFieldRoutingModule,
    FormsModule,

    TranslateModule,
    AutocompleteModule,
    ColorPickerModule,

    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatBadgeModule,
    MatMenuModule,
    MatRippleModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatButtonToggleModule,
  ],
  exports: components,
  declarations: components,
  providers: [ObjectFieldService, FieldService],
})
export class ObjectFieldModule {}
