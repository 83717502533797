<div
  #fileUsageList
  class="fileusages"
  [style.touch-action]="'pan-y'"
  (swipeleft)="swiping = true; setViewIndex(viewIndex + displayedImages)"
  (swiperight)="swiping = true; setViewIndex(viewIndex - displayedImages)"
  (click)="swiping && $event.stopPropagation()"
  (apexInView)="onInView($event)">
  <div
    *ngFor="let f of fileUsages; let i = index"
    class="fileusage"
    [title]="f?.fileName"
    [style.transform]="'translateX(' + (i - viewIndex) * 100 + '%)'"
    [style.width]="100 / displayedImages + '%'"
    [ngClass]="{
      selected: displayedImages > 1 && selectedIndex === i,
      'image-text-background': ref?.childNodes?.length,
    }"
    (click)="swiping ? $event.stopPropagation() : setSelectedIndex(i)"
    mat-ripple
    [matRippleDisabled]="displayedImages === 1"
    [matBadgeHidden]="!f?.Markings?.length"
    [matBadge]="f?.Markings?.length">
    <apex-file-preview
      *ngIf="size && !f.lazy"
      [fileUsage]="f"
      [file]="f.File"
      [size]="size"
      [fit]="fit"
      [use1kImage]="use1kImage"
      [useOldImageLoader]="useOldImageLoader">
    </apex-file-preview>
    <div
      class="tac mat-caption width-100 truncate position-absolute bottom-0"
      *ngIf="showCaption">
      {{ f.fileName }}
    </div>
  </div>
  <div
    class="placeholder-image load-image-smooth"
    *ngIf="!fileUsages || !fileUsages.length"></div>
  <div
    class="navigators"
    *ngIf="navigation && fileUsages && fileUsages.length > displayedImages">
    <button
      mat-icon-button
      class="left"
      type="button"
      (mousedown)="$event.stopPropagation()"
      (click)="$event.stopPropagation(); setViewIndex(viewIndex - displayedImages)"
      [class.hide]="viewIndex <= 0">
      <mat-icon>keyboard_arrow_left</mat-icon>
    </button>
    <button
      mat-icon-button
      class="right"
      type="button"
      (mousedown)="$event.stopPropagation()"
      (click)="$event.stopPropagation(); setViewIndex(viewIndex + displayedImages)"
      [class.hide]="viewIndex >= fileUsages?.length - 1">
      <mat-icon>keyboard_arrow_right</mat-icon>
    </button>
  </div>

  <span
    #ref
    class="position-absolute aml amb bottom-0 left-0 image-text df c"
    [class.hide]="!ref?.childNodes?.length">
    <ng-content
      select="[title]"
      #ref></ng-content>
    <ng-content
      select="[subtitle]"
      #ref></ng-content>
  </span>
</div>
