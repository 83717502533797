import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { of } from 'rxjs';
import { ObjectField } from '../../../../models/object-field';
import { getObjectId } from '../../utils';
import { ObjectFieldService } from './object-field.service';

const getFieldId = (route: ActivatedRouteSnapshot): number => Number(route.paramMap.get('fid')) ?? 0;

export const ObjectFieldResolver: ResolveFn<ObjectField> = (route: ActivatedRouteSnapshot) => {
  const service = inject(ObjectFieldService);

  const oid = getObjectId(route);
  const fid = getFieldId(route);

  return !fid ? of(null) : service.get(oid, fid);
};

export const ObjectFieldsResolver: ResolveFn<ObjectField[]> = (route: ActivatedRouteSnapshot) => {
  const service = inject(ObjectFieldService);

  return service.query(getObjectId(route));
};
