import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatBadge } from '@angular/material/badge';
import { MatAnchor, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatMenu, MatMenuContent, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, RouterLink } from '@angular/router';
import {
  ResponseAgreementDto,
  ResponseObjectFieldDto,
  UpdateAgreementDto,
} from '../../../../../../generated/apex-rest';
import { AgreementRestService } from '../../features/object/project/agreement/agreement-rest.service';
import { Autocomplete } from '../../models/autocomplete';
import { snack } from '../../modules/snack.module';
import { AutocompletePickerModule } from '../autocomplete-picker/autocomplete-picker.module';
import { AutocompleteModule } from '../autocomplete/autocomplete.module';
import { BoxModule } from '../box/box.module';
import { ColorPickerModule } from '../color-picker/color-picker.module';
import { TranslateModule } from '../translate/translate.module';
import { ObjectFieldRestService } from './object-field-rest.service';

@Component({
  selector: 'apex-object-fields',
  templateUrl: './object-field.component.html',
  standalone: true,
  imports: [
    TranslateModule,
    AutocompleteModule,
    CommonModule,
    AutocompletePickerModule,
    BoxModule,
    MatIcon,
    MatIconButton,
    MatMenu,
    MatMenuItem,
    MatMenuContent,
    MatMenuTrigger,
    MatBadge,
    ColorPickerModule,
    RouterLink,
    MatAnchor,
    FormsModule,
  ],
  providers: [ObjectFieldRestService, AgreementRestService],
})
export class ObjectFieldComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private service: ObjectFieldRestService,
    private agreementRestService: AgreementRestService,
  ) {}

  objectId: number;
  agreementId: number;

  objectField: ResponseObjectFieldDto;
  updateAgreementDto: UpdateAgreementDto;
  responseAgreementDto: ResponseAgreementDto;

  async readObjectFields(objectId: number, objectFieldId: number): Promise<void> {
    this.objectField = await this.service.readObjectField(objectId, objectFieldId);
  }

  async readAgreementObjectField(agreementId: number): Promise<void> {
    this.responseAgreementDto = await this.agreementRestService.read(agreementId);

    const objectFieldId = this.responseAgreementDto.ObjectFieldId;

    await this.readObjectFields(this.objectId, objectFieldId);
  }

  ngOnInit(): void {
    this.objectId = this.route.snapshot.data.agreement.ObjectId;
    this.agreementId = this.route.snapshot.data.agreement.id;

    void this.readAgreementObjectField(this.agreementId);
  }

  async updateObjectField(event?: Autocomplete): Promise<void> {
    this.updateAgreementDto = {
      ObjectFieldId: event?.id ?? null,
    };

    try {
      await this.agreementRestService.updateObjectField(this.agreementId, this.updateAgreementDto);

      await this.readAgreementObjectField(this.agreementId);

      snack('Discipline saved');
    } catch (error) {
      snack('Failed to save discipline');
    }
  }
}
