<section>
  <!-- hard coded href link for inspection -->
  <a
    *ngIf="sidebarItem?.href; else routerLink"
    [href]="sidebarItem.href">
    <mat-icon>{{ sidebarItem.icon }}</mat-icon>
    <div>{{ sidebarItem.translation | translate }}</div>

    <button
      *ngIf="!pinned && showPinButtons; else removePin"
      (click)="pinSidebarItem(sidebarItem); $event.stopPropagation(); $event.preventDefault()"
      mat-icon-button
      type="button"
      [matTooltip]="'Add pin' | translate">
      <mat-icon>push_pin</mat-icon>
    </button>
  </a>

  <ng-template #routerLink>
    <a
      *ngIf="sidebarItem?.routerLink"
      [routerLink]="sidebarItem.routerLink | stringToRouterLink"
      [queryParams]="sidebarItem.routerLink | stringToQueryParams"
      routerLinkActive="active">
      <mat-icon>{{ sidebarItem.icon }}</mat-icon>
      <div>{{ sidebarItem.translation | translate }}</div>

      <button
        *ngIf="!pinned && showPinButtons; else removePin"
        (click)="pinSidebarItem(sidebarItem); $event.stopPropagation(); $event.preventDefault()"
        mat-icon-button
        type="button"
        [matTooltip]="'Add pin' | translate">
        <mat-icon>push_pin</mat-icon>
      </button>
    </a>
  </ng-template>

  <ng-container *ngIf="sidebarItem?.children?.length">
    <div class="nested">
      <apex-sidebar-item
        *ngFor="let child of sidebarItem.children"
        [showPinButtons]="false"
        [sidebarItem]="child">
      </apex-sidebar-item>
    </div>
  </ng-container>
</section>

<ng-template #removePin>
  <ng-container *ngIf="showPinButtons">
    <button
      (click)="deletePinnedSidebarItem(sidebarItem); $event.stopPropagation(); $event.preventDefault()"
      class="rotate-45"
      mat-icon-button
      type="button"
      [matTooltip]="'Remove pin' | translate">
      <mat-icon>push_pin</mat-icon>
    </button>

    <button
      (click)="$event.stopPropagation(); $event.preventDefault()"
      cdkDragHandle
      mat-icon-button
      [matTooltip]="'Drag' | translate">
      <mat-icon>drag_handle</mat-icon>
    </button>
  </ng-container>
</ng-template>
