import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, mergeMap } from 'rxjs/operators';

import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { ObjectField } from 'projects/apex/src/app/models/object-field';
import { snack, snackErr } from 'projects/apex/src/app/modules/snack.module';
import { constants } from 'projects/apex/src/app/utils/constants';
import { sortNatural } from 'projects/apex/src/app/utils/functions';
import { ObjectFieldService } from './object-field.service';

@Component({
  selector: 'apex-fields',
  templateUrl: './list.component.html',
})
export class ObjectFieldsComponent implements OnInit, OnDestroy {
  fields: ObjectField[] = [];
  filteredFields: ObjectField[] = [];

  private subscriptions = new Subscription();

  private saveSubject: Subject<ObjectField> = new Subject();
  showWithContractors = true;
  showWithoutContractors = true;

  constructor(
    private route: ActivatedRoute,
    private fieldService: ObjectFieldService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.showWithContractors = this.route.snapshot.queryParams.fieldHasContractor === 'true';
    this.showWithoutContractors = this.route.snapshot.queryParams.fieldHasContractor === 'false';

    if (this.route.snapshot.queryParams.fieldHasContractor === undefined) {
      this.showWithContractors = true;
      this.showWithoutContractors = true;
    }

    this.fields = this.route.snapshot.data.objectFields
      ? sortNatural(this.route.snapshot.data.objectFields, 'fullName')
      : [];

    this.filteredFields = this.route.snapshot.data.objectFields;

    if (this.route.snapshot.queryParams.fieldHasContractor === 'true') {
      this.filteredFields = this.fields.filter((f) => f.Contractors?.length > 0);
    } else if (this.route.snapshot.queryParams.fieldHasContractor === 'false') {
      this.filteredFields = this.fields.filter((f) => !f.Contractors?.length);
    } else if (this.route.snapshot.queryParams.showNone === 'true') {
      this.filteredFields = this.fields.filter((f) => !f.Contractors);
    } else if (this.route.snapshot.queryParams.showAll === 'true') {
      this.filteredFields = this.fields;
    }

    this.fields.forEach((f) => {
      f.color = f.color ? f.color : f.Field?.color;
    });

    this.subscriptions.add(
      this.saveSubject
        .pipe(
          debounceTime(constants.inputDebounceTime),
          mergeMap((f) => this.fieldService.save(f)),
        )
        .subscribe({
          next: (_) => snack(t('Field saved')),
          error: (err) => snackErr(t('Failed to save field'), err),
        }),
    );
  }

  async updateUrlParams(): Promise<void> {
    const queryParams = {};

    if (!this.showWithContractors && !this.showWithoutContractors) {
      this.showWithContractors = true;
      queryParams['fieldHasContractor'] = 'true';
      this.filteredFields = this.fields.filter((f) => f.Contractors?.length > 0);
    } else if (this.showWithContractors && this.showWithoutContractors) {
      this.showWithContractors = false;
      queryParams['fieldHasContractor'] = 'false';
      this.filteredFields = this.fields.filter((f) => !f.Contractors?.length);
    } else if (this.showWithContractors && !this.showWithoutContractors) {
      this.showWithContractors = false;
      queryParams['showNone'] = 'true';
      this.filteredFields = this.fields.filter((f) => !f.Contractors);
    } else if (!this.showWithContractors && this.showWithoutContractors) {
      this.showWithContractors = true;
      queryParams['showAll'] = 'true';
      this.filteredFields = this.fields.filter((f) => f.Contractors);
    } else {
      queryParams['fieldHasContractor'] = undefined;
    }

    await this.router.navigate([], { queryParams });
  }

  async updateUrlParamsWithout(): Promise<void> {
    const queryParams = {};

    if (!this.showWithContractors && !this.showWithoutContractors) {
      this.showWithoutContractors = true;
      queryParams['fieldHasContractor'] = 'false';
      this.filteredFields = this.fields.filter((f) => !f.Contractors?.length);
    } else if (this.showWithoutContractors && this.showWithContractors) {
      this.showWithoutContractors = false;
      queryParams['fieldHasContractor'] = 'true';
      this.filteredFields = this.fields.filter((f) => f.Contractors?.length > 0);
    } else if (this.showWithoutContractors && !this.showWithContractors) {
      this.showWithoutContractors = false;
      queryParams['showNone'] = 'true';
      this.filteredFields = this.fields.filter((f) => !f.Contractors);
    } else if (!this.showWithoutContractors) {
      this.showWithoutContractors = true;
      queryParams['showAll'] = 'true';
      this.filteredFields = this.fields.filter((f) => f.Contractors);
    } else {
      queryParams['fieldHasContractor'] = undefined;
    }

    await this.router.navigate([], { queryParams });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  colorChange(field: ObjectField): void {
    this.saveSubject.next(field);
  }
}
