import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subscription, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';
import { File } from '../../models/file';
import { FileUsage } from '../../models/file-usage';
import { getURLFromCache } from '../../pipes/url-cache/url-cache.pipe';
import { presentDownloadURL, urlExpired } from '../../utils/functions';
import { FileUsageService } from '../file-usage/file-usage.service';
import { FileService } from '../file-usage/file.service';
import {
  ImageFit,
  defaultFileIcon,
  defaultImageQuality,
  defaultSize,
  getThumbUrlForFile,
  videoFileIcon,
} from './file-preview.utils';

type FilePreview = Pick<File, 'id' | 'name' | 'description' | 'type' | 'size' | 'signed'> & {
  createdAt?: string | Date;
  updatedAt?: string | Date;
  FileUsages?: [
    {
      id: string;
    },
  ];
};

@Component({
  selector: 'apex-file-preview',
  templateUrl: './file-preview.component.html',
})
export class FilePreviewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() file: FilePreview;

  @Input() size: { width: number; height: number } | string | number;
  @Input() showSize = false;
  @Input() showName = false;
  @Input() fit: ImageFit = 'cover';
  @Input() quality = defaultImageQuality;
  @Input() showDates = false;

  @Input() use1kImage = false;
  @Input() useOldImageLoader = false;

  @Input() fileUsage: FileUsage = null;

  @Input() showPlaceholder = true;

  @Input() sizeContainer = true;

  @Output() loaded = new EventEmitter<boolean>();

  @HostBinding('style.width.px')
  get width(): number {
    if (!(this.innerWidth > 0)) {
      return defaultSize.width;
    }

    return this.innerWidth;
  }

  @HostBinding('style.height.px')
  get height(): number {
    if (!(this.innerHeight > 0)) {
      return defaultSize.height;
    }

    return this.innerHeight;
  }

  get sizedWidth(): number | null {
    if (this.sizeContainer) {
      return this.width;
    }

    return null;
  }

  get sizedHeight(): number | null {
    if (this.sizeContainer) {
      return this.height;
    }

    return null;
  }

  thumbUrl: string | SafeUrl = '';
  thumbIcon = '';

  loadFailed: boolean;

  download$$: Subscription;

  private innerWidth: number = defaultSize.width;
  private innerHeight: number = defaultSize.height;

  constructor(
    private service: FileService,
    private fuService: FileUsageService,
    private domSanitizer: DomSanitizer,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.file) {
      void this.fileChange();
    }

    if (changes.size) {
      this.sizeChange();
    }
  }

  ngOnInit(): void {
    if (!this.size) {
      this.size = defaultSize;
    }

    this.sizeChange();
  }

  ngOnDestroy(): void {
    this.download$$?.unsubscribe();
    // this.pdfPreviewSubject$$.unsubscribe();
  }

  async fileChange(): Promise<void> {
    if (!this.file || !this.file.name) {
      return;
    }

    if (this.file.type.startsWith('image/')) {
      if (this.file.signed && this.file.signed.url) {
        this.thumbIcon = null;

        if (this.useOldImageLoader) {
          this.thumbUrl = getThumbUrlForFile(this.file, this.width, this.height, this.quality, this.fit);
        } else {
          const thumbUrl = !this.use1kImage ? this.file.signed.thumbnailUrl : this.file.signed.imageUrl1k;

          this.thumbUrl = getURLFromCache(thumbUrl);
        }
      }
    } else if (this.file.type === 'application/pdf') {
      this.thumbUrl = this.file.signed.thumbnailUrl;
      this.thumbIcon = null;
    } else if (this.file.type?.startsWith('video/')) {
      this.thumbIcon = videoFileIcon;
    } else {
      this.thumbIcon = defaultFileIcon;
      this.thumbUrl = null;
    }
  }

  errorHandler(): void {
    this.loadFailed = true;
  }

  loadHandler(): void {
    this.loaded.emit(true);
  }

  sizeChange(): void {
    if (typeof this.size === 'string') {
      this.size = parseInt(this.size, 10);
    }

    if (typeof this.size === 'number') {
      this.innerWidth = this.size;
      this.innerHeight = this.size;
    } else if (typeof this.size === 'object') {
      this.innerWidth = this.size.width;
      this.innerHeight = this.size.height;
    } else {
      this.innerWidth = defaultSize.width;
      this.innerHeight = defaultSize.height;
    }

    if (this.innerWidth < 1) {
      this.innerWidth = defaultSize.width;
    }

    if (this.innerHeight < 1) {
      this.innerHeight = defaultSize.height;
    }

    void this.fileChange();
  }

  download(): void {
    of(this.file)
      .pipe(
        mergeMap((file) => {
          if (!urlExpired(file.signed.url)) {
            return of(file).pipe(
              map((f) => ({
                url: f.signed.url,
                name: f.name,
              })),
            );
          }

          if (this.fileUsage) {
            return this.fuService.get(this.fileUsage.id).pipe(
              take(1),
              map((fu) => ({
                url: fu.File.signed.url,
                name: fu.File.name,
              })),
            );
          }

          return this.service.url(this.file.id).pipe(
            map((urls) => ({
              url: urls.signed,
              name: this.file.name,
            })),
          );
        }),
        take(1),
      )
      .subscribe((f) => {
        presentDownloadURL(f.url, f.name);
      });
  }
}
