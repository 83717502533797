import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { mergeMap, skipWhile, take } from 'rxjs/operators';

import { AutocompleteComponent } from 'projects/apex/src/app/components/autocomplete/autocomplete.component';
import { ConfirmDialogComponent } from 'projects/apex/src/app/components/confirm-dialog/confirm-dialog.component';
import { t } from 'projects/apex/src/app/components/translate/translate.function';
import { ObjectFieldService } from 'projects/apex/src/app/features/object/project/field/object-field.service';
import { Autocomplete } from 'projects/apex/src/app/models/autocomplete';
import { ObjectField } from 'projects/apex/src/app/models/object-field';
import { User } from 'projects/apex/src/app/models/user';
import { UserService } from 'projects/apex/src/app/services/user/user.service';
import { constants } from 'projects/apex/src/app/utils/constants';
import { FieldService } from '../../../field/field.service';

@Component({
  selector: 'apex-field',
  templateUrl: './form.component.html',
})
export class ObjectFieldComponent implements OnInit {
  @ViewChild('contractorAuto') auto: AutocompleteComponent;

  ObjectId: number;
  objectField: ObjectField;
  hexColorPattern = constants.pattern.hexColor;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private objectFieldService: ObjectFieldService,
    private fieldService: FieldService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.ObjectId = Number(this.route.snapshot.params.pid);
    this.objectField = this.route.snapshot.data.objectField;

    if (!this.objectField) {
      this.objectField = new ObjectField();
      this.objectField.ObjectId = this.ObjectId;
    }

    if (!this.ObjectId || this.objectField.ObjectId !== this.ObjectId) {
      this.back();
    }
  }

  addContractor(a: Autocomplete): void {
    if (a) {
      this.auto?.writeValue(null);

      const existingUser = this.objectField?.Contractors?.find((c) => c.id === a.id);

      if (!existingUser) {
        this.userService.get(a.id).subscribe({
          next: (user) => {
            if (this.objectField.Contractors) {
              this.objectField.Contractors.unshift(user);
            } else {
              this.objectField.Contractors = [user];
            }

            this.auto?.blur();
          },
        });
      }
    }
  }

  removeContractor(user: User): void {
    const idx = this.objectField.Contractors.indexOf(user);

    if (idx !== -1) {
      this.objectField.Contractors.splice(idx, 1);
    }
  }

  saveField(): void {
    this.objectFieldService.save(this.objectField).subscribe({
      next: () => {
        this.back();
      },
    });
  }

  deleteField(): void {
    this.dialog
      .open(ConfirmDialogComponent, { data: { text: t('Are you sure you want to delete this item?') } })
      .afterClosed()
      .pipe(
        skipWhile((r) => !r),
        mergeMap(() => this.objectFieldService.delete(this.objectField)),
      )
      .subscribe({
        next: () => {
          this.back();
        },
      });
  }

  back(): void {
    if (this.ObjectId > 0) {
      void this.router.navigate(['object', 'project', this.ObjectId, 'field']);
    }
  }

  baseFieldChange(autoComplete: Autocomplete): void {
    if (!autoComplete) {
      return;
    }

    const { id } = autoComplete;

    this.objectField.FieldId = id;

    this.fieldService
      .get(id)
      .pipe(take(1))
      .subscribe({
        next: (field) => {
          this.objectField.Field = field;
        },
      });
  }
}
