import { Injectable } from '@angular/core';
import { ObjectFieldApi, ResponseObjectFieldDto } from '../../../../../../generated/apex-rest';
import { restAxiosConfig } from '../../utils/rest-axios-config';

@Injectable()
export class ObjectFieldRestService {
  private readonly objectFieldApi = new ObjectFieldApi(restAxiosConfig());

  async readObjectField(objectId: number, objectFieldId: number): Promise<ResponseObjectFieldDto> {
    const response = await this.objectFieldApi.objectFieldControllerRead(objectId, objectFieldId);

    return response.data.Entity;
  }
}
