import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatListItem, MatListItemIcon, MatListItemLine, MatListItemMeta, MatNavList } from '@angular/material/list';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { AgreementPageOptionsOrderBy, ResponseAgreementDto } from 'generated/apex-rest';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';
import { DaterModule } from '../../pipes/dater/dater.module';
import { HighlightPipeModule } from '../../pipes/highlight/highlight-pipe.module';
import { constants } from '../../utils/constants';
import { PageInfoModule } from '../page-info/page-info.module';
import { TranslateModule } from '../translate/translate.module';
import { AgreementCompanyListService } from './agreement-company-list.service';

@Component({
  selector: 'apex-agreement-company-list',
  templateUrl: './agreement-company-list.component.html',
  standalone: true,
  imports: [
    CommonModule,
    PageInfoModule,
    TranslateModule,
    MatFormField,
    MatInput,
    ReactiveFormsModule,
    FormsModule,
    MatListItem,
    MatNavList,
    MatListItemIcon,
    MatIcon,
    MatListItemLine,
    RouterLink,
    MatListItemMeta,
    MatTooltip,
    MatPaginator,
    HighlightPipeModule,
    DaterModule,
    MatCheckbox,
  ],
  providers: [AgreementCompanyListService],
})
export class AgreementCompanyListComponent implements OnInit, OnDestroy {
  constructor(private agreementCompanyListService: AgreementCompanyListService) {}

  agreements$: Observable<ResponseAgreementDto[]>;

  private subscription = new Subscription();
  private searchSubject = new Subject<void>();

  search = '';
  active = false;

  count: number;
  page = 0;
  limit = 5;

  order: 'ASC' | 'DESC' = 'ASC';
  orderBy: AgreementPageOptionsOrderBy = AgreementPageOptionsOrderBy.CreatedAt;

  ngOnInit(): void {
    this.agreements$ = this.searchSubject.pipe(
      debounceTime(constants.inputDebounceTime),
      startWith(null),
      switchMap(() =>
        this.agreementCompanyListService.readCollection(
          this.page,
          this.limit,
          this.order,
          this.orderBy,
          undefined,
          undefined,
          this.search,
          this.active,
          this.search,
        ),
      ),
    );
  }

  onChangeSearch(): void {
    this.page = 0;

    this.searchSubject.next();
  }

  onChangePage(e: PageEvent): void {
    this.limit = e.pageSize;
    this.page = e.pageIndex;

    this.searchSubject.next();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
