export enum SidebarItemAccess {
  IsPayed = 'isPayed',
  IsAdmin = 'isAdmin',
  HasCustomerRightCommercial = 'hasCustomerRightCommercial',
  HasCustomerRightSafety = 'hasCustomerRightSafety',
  HasCustomerRightAddon = 'hasCustomerRightAddon',
  HasCustomerRightSigning = 'hasCustomerRightSigning',
  HasAddonWork = 'hasAddonWork',
  HasPortal = 'hasPortal',
  UserAccessToAddonCaseRequest = 'userAccessToAddonCaseRequest',
}
