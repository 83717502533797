import { Injectable } from '@angular/core';
import { AgreementApi, AgreementPageOptionsOrderBy, ResponseAgreementDto } from 'generated/apex-rest';
import { restAxiosConfig } from '../../utils/rest-axios-config';

@Injectable()
export class AgreementCompanyListService {
  private readonly agreementApi = new AgreementApi(restAxiosConfig());

  async readCollection(
    page: number,
    limit: number,
    order?: 'ASC' | 'DESC',
    orderBy?: AgreementPageOptionsOrderBy,
    objectId?: number,
    id?: number,
    name?: string,
    active?: boolean,
    search?: string,
  ): Promise<ResponseAgreementDto[]> {
    const response = await this.agreementApi.agreementControllerReadCollectionForCompany(
      page,
      limit,
      order,
      orderBy,
      objectId,
      id,
      name,
      active,
      search,
    );

    return response.data.Collection;
  }
}
