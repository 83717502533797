import { Injectable } from '@angular/core';
import { APIService } from 'projects/apex/src/app/services/http/http.service';
import { CollectionResponse, DeleteResponse, EntityResponse } from 'projects/apex/src/app/utils/types';
import { Observable } from 'rxjs';
import { Obj } from '../../object.model';
import { Project } from '../project.model';
import { Agreement } from './agreement.model';

@Injectable()
export class AgreementService extends APIService<Agreement> {
  queryByObject(
    objectId: number,
    page: number,
    limit: number,
    search: string,
    active: boolean,
    requireReports: boolean,
  ): Observable<CollectionResponse<Agreement>> {
    const url = this.url(objectId);

    return this.http.get<CollectionResponse<Agreement>>(
      url,
      this.options({
        params: {
          page: String(page),
          limit: String(limit),
          search,
          active: String(active),
          requireReports: String(requireReports),
        },
      }),
    );
  }

  getByObject(objectId: number, agreementId: number): Observable<EntityResponse<Agreement>> {
    const url = this.url(objectId, agreementId);

    return this.http.get<EntityResponse<Agreement>>(url, this.options());
  }

  saveByObject(agreement: Agreement): Observable<EntityResponse<Agreement>> {
    const url = this.url(agreement.ObjectId, agreement.id);

    return this.http.post<EntityResponse<Agreement>>(url, agreement, this.options());
  }

  deleteByObject(agreement: Agreement): Observable<DeleteResponse> {
    const url = this.url(agreement.ObjectId, agreement.id);

    return this.http.delete<DeleteResponse>(url, this.options());
  }

  getTopLevelObject(agreementId: number): Observable<Project> {
    const url = `${this.apiUrl}/agreement/${agreementId}`;

    return this.http.get<Project>(url, this.options());
  }

  tree(agreementId: number): Observable<Obj[]> {
    const url = `${this.apiUrl}/agreement/${agreementId}/tree`;

    return this.http.get<Obj[]>(url, this.options());
  }

  private url(objectId: number, agreementId?: number): string {
    return agreementId
      ? `${this.apiUrl}/object/${objectId}/agreement/${agreementId}`
      : `${this.apiUrl}/object/${objectId}/agreement`;
  }
}
