import { booleanAttribute, Component, forwardRef, HostBinding, Injectable, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel, UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { padStart } from 'lodash-es';
import moment, { Moment } from 'moment';
import { noop } from 'rxjs';
import { ValidateDateTimeInputDirective } from './date-time.directive';

@Injectable()
export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl): boolean {
    return control?.status === 'INVALID';
  }
}

export class InvalidTimeInputErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl): boolean {
    return control?.hasError('invalidTimeInput');
  }
}

@Component({
  selector: 'apex-date-time',
  templateUrl: './date-time.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
    {
      provide: ErrorStateMatcher,
      useClass: CustomErrorStateMatcher,
    },
  ],
})
export class DateTimeComponent implements ControlValueAccessor {
  @Input() dateTitle: string;
  @Input() timeTitle: string;

  @Input() reverseViewOrder = false;

  @Input() minDate: Moment;
  @Input() maxDate: Moment;

  @Input() disableDate = false;
  @Input({ transform: booleanAttribute }) disableTime = false;

  @Input() defaultTime: string;

  @ViewChild('timeInput') timeInput: NgModel;
  @ViewChild('dateInput') dateInput: NgModel;

  @HostBinding('attr.apexDateTime') apexDateTime = new ValidateDateTimeInputDirective();

  disabled = false;
  times = this.buildTimesArray();
  invalidTimeInputErrorStateMatcher = new InvalidTimeInputErrorStateMatcher();

  private innerValue: Date | Moment;
  private innerDateValue: Date | Moment;
  private innerTimeValue: string;

  private onTouched: () => void = noop;
  private onChanged: (v: Date | Moment) => void = noop;

  get date(): Date | Moment {
    return this.innerDateValue;
  }

  set date(val: Date | Moment) {
    if (this.defaultTime && !this.innerTimeValue) {
      this.innerTimeValue = this.defaultTime;
    }

    if (!this.innerTimeValue) {
      this.innerTimeValue = moment().format('HH:mm');
    }

    const newVal = this.createDateTime(val, this.innerTimeValue);

    this.innerDateValue = val;
    this.innerValue = moment(newVal).isValid() ? newVal : null;

    this.onChanged(this.innerValue);
  }

  onDateBlur(): void {
    const newDateVal = moment(this.innerValue);

    if (newDateVal.isValid() && this.innerValue) {
      this.innerDateValue = this.innerValue;
    }

    this.onTouched();
  }

  get time(): string {
    return this.innerTimeValue;
  }

  set time(val: string) {
    if (!this.innerDateValue) {
      this.innerDateValue = moment();
    }

    const newVal = this.createDateTime(this.innerDateValue, val);

    this.innerTimeValue = val;
    this.innerValue = moment(newVal).isValid() ? newVal : null;

    this.onChanged(this.innerValue);
  }

  onTimeBlur(): void {
    const newTimeVal = moment(this.innerTimeValue, 'HH:mm');

    if (newTimeVal.isValid() && this.innerTimeValue.trim()) {
      this.time = this.getTimeString(newTimeVal);
    }

    this.onTouched();
  }

  get value(): Date | Moment {
    return this.innerValue;
  }

  set value(val: Date | Moment) {
    this.innerValue = val;
    this.onChanged(val);
  }

  writeValue(val: Date | Moment): void {
    if (val) {
      const dateVal = moment(val);

      if (dateVal.isValid() && val) {
        const { date, time } = this.splitDateTime(dateVal);

        this.innerDateValue = date;
        this.innerTimeValue = time;
        this.innerValue = dateVal;
      }
    } else {
      this.innerDateValue = null;
      this.innerTimeValue = null;
      this.innerValue = null;
    }
  }

  registerOnChange(fn: () => void): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  buildTimesArray(): string[] {
    const times: string[] = [];

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        const time = `${padStart(i.toString(), 2, '0')}:${15 * j || '00'}`;

        times.push(time);
      }
    }

    return times;
  }

  splitDateTime(date: Date | Moment): { date: Moment; time: string } {
    const tempDate = moment(date);

    if (tempDate.isValid()) {
      return {
        date: tempDate,
        time: `${padStart(tempDate.hour().toString(), 2, '0')}:${padStart(tempDate.minute().toString(), 2, '0')}`,
      };
    }

    return null;
  }

  getTimeString(date: Date | Moment): string {
    const tempDate = moment(date);

    if (tempDate.isValid()) {
      return `${padStart(tempDate.hour().toString(), 2, '0')}:${padStart(tempDate.minute().toString(), 2, '0')}`;
    }

    return null;
  }

  createDateTime(date: Date | Moment, time: string): Moment | null {
    const mTime = moment(time, 'HH:mm');
    const mDate = moment(date);
    let valid = false;

    if (mDate.isValid() && mTime.isValid()) {
      mDate.set({
        hour: mTime.get('hour'),
        minute: mTime.get('minute'),
      });

      valid = true;
    }

    return valid ? mDate : null;
  }
}
