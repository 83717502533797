<apex-box
  class="f1"
  expanded>
  <ng-container title> <T str="Discipline"></T></ng-container>
  <div class="ap">
    <apex-autocomplete-picker
      [params]="{
        ObjectId: objectId,
      }"
      cdkFocusInitial
      [multiple]="false"
      type="ObjectField"
      (optionAdd)="updateObjectField($event)"
      (optionRemoved)="updateObjectField(null)"
      [label]="'Discipline' | translate">
    </apex-autocomplete-picker>

    <ng-container *ngIf="objectField">
      <div class="df c mat-elevation-z2">
        <div class="ap df c f1">
          <h2 class="rmv">
            {{ objectField.Field.name }}
            <span *ngIf="objectField.name"> - {{ objectField.name }}</span>
          </h2>
          <div class="amts mat-caption">{{ objectField.description }}</div>
        </div>
        <div class="actions aps df ac jsb">
          <a
            mat-button
            color="primary"
            [routerLink]="['/object/project', objectId, 'field', objectField.id]">
            <T
              str="Edit"
              context="action"
              _context="action">
            </T>
          </a>

          <div class="df ac amrs">
            <apex-color-picker [(ngModel)]="objectField.Field.color"></apex-color-picker>
            <button
              mat-icon-button
              [disabled]="!objectField.Contractors?.length"
              [matMenuTriggerFor]="contractorsMenu"
              [matMenuTriggerData]="{ field: objectField.Contractors }"
              (click)="$event.stopPropagation(); $event.preventDefault()"
              (mousedown)="$event.stopPropagation()">
              <mat-icon
                [matBadge]="objectField.Contractors?.length"
                [matBadgeHidden]="!objectField.Contractors?.length">
                person
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div
    class="apv df jc ac"
    *ngIf="!objectField">
    <p>
      <T str="No disciplines found"></T>
    </p>
  </div>
</apex-box>

<mat-menu #contractorsMenu="matMenu">
  <ng-template
    matMenuContent
    let-f="field">
    <div
      mat-menu-item
      *ngFor="let user of f">
      <div class="df ac justify-space-between gap">
        <div class="f1">{{ user.name }} ({{ user.Company?.name }})</div>
      </div>
    </div>
  </ng-template>
</mat-menu>
