import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FileUsageModule } from 'projects/apex/src/app/components/file-usage/file-usage.module';
import { ModelRoleModule } from 'projects/apex/src/app/components/model-role/model-role.module';
import { TextUsageModule } from 'projects/apex/src/app/components/text-usage/text-usage.module';
import { TranslateModule } from 'projects/apex/src/app/components/translate/translate.module';
import { HistoryBackModule } from 'projects/apex/src/app/directives/history-back/history-back.module';
import { DaterModule } from 'projects/apex/src/app/pipes/dater/dater.module';
import { HighlightPipeModule } from 'projects/apex/src/app/pipes/highlight/highlight-pipe.module';
import { IconPipeModule } from 'projects/apex/src/app/pipes/icon/icon-pipe.module';
import { BoxModule } from '../../../../components/box/box.module';
import { ObjectFieldComponent } from '../../../../components/object-field/object-field.component';
import { ObjectSelectorModule } from '../../components/selector/object-selector.module';
import { ObjectFieldModule } from '../field/object-field.module';
import { AgreementRoutingModule } from './agreement-routing.module';
import { AgreementService } from './agreement.service';
import { CaseAgreementInfoComponent } from './case-agreement-info.component';
import { AgreementComponent } from './form.component';
import { AgreementsComponent } from './list.component';

const mine = [AgreementComponent, AgreementsComponent, CaseAgreementInfoComponent];

@NgModule({
  imports: [
    AgreementRoutingModule,

    CommonModule,
    FormsModule,

    MatListModule,
    MatButtonModule,
    RouterModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatTooltipModule,
    IconPipeModule,
    MatRippleModule,
    MatPaginatorModule,

    TranslateModule,
    ObjectSelectorModule,
    FileUsageModule,
    ModelRoleModule,
    HistoryBackModule,
    HighlightPipeModule,
    TextUsageModule,
    DaterModule,
    BoxModule,
    ObjectFieldModule,
    ObjectFieldComponent,
  ],
  exports: mine,
  declarations: mine,
  providers: [AgreementService],
})
export class AgreementModule {}
