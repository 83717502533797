import { Pipe, PipeTransform } from '@angular/core';
import { Autocomplete } from '../../models/autocomplete';

@Pipe({
  name: 'distanceToTopInCollection',
  standalone: true,
})
export class DistanceToTopInCollectionPipe implements PipeTransform {
  transform<T>(current: T, collection: T[], field: keyof T): number {
    // recursively find the distance to the top of the collection
    // if the field is not found, return null
    // stopping after 25 iterations to prevent infinite loops

    let distance = 0;
    let i = 0;

    while (i < 25) {
      const parent = collection.find((item) => item[field] === current[field]);

      if (!parent) {
        return distance;
      }

      current = parent;
      distance++;
      i++;
    }

    return distance;
  }
}

type ModelWithParent = Autocomplete;

@Pipe({
  name: 'distanceToTopInCollectionWithParent',
  standalone: true,
})
export class DistanceToTopInCollectionPipeWithParent implements PipeTransform {
  transform(current: ModelWithParent, collection: ModelWithParent[]): number {
    let distance = 0;
    let i = 0;

    while (i < 25) {
      const parent = collection.find((item) => item.id === current.Parent?.id);

      if (!parent) {
        return distance;
      }

      current = parent;
      distance++;
      i++;
    }

    return distance;
  }
}
