<apex-page-info>
  <ng-container title>
    <T str="Agreements"></T>
  </ng-container>
</apex-page-info>

<div class="df w apl ac amh amt mat-elevation-z2 search-container">
  <mat-form-field class="f1 amr amt">
    <input
      matInput
      type="text"
      name="searchInput"
      autofocus
      [placeholder]="'Search' | translate"
      [(ngModel)]="search"
      (ngModelChange)="onChangeSearch()" />
  </mat-form-field>

  <mat-checkbox
    class="amr p-amb"
    [(ngModel)]="active"
    (ngModelChange)="onChangeSearch()">
    <T str="Show only active"></T>
  </mat-checkbox>
</div>

<ng-container *ngIf="agreements$ | async as agreements">
  <section class="list-container am">
    <mat-nav-list class="mat-elevation-z2 am">
      <ng-container *ngFor="let agreement of agreements">
        <mat-list-item
          lines="3"
          [routerLink]="['/object/project/' + agreement.ObjectId + '/agreement/' + agreement.id]">
          <span
            matListItemTitle
            *ngIf="agreement.name"
            [innerHTML]="agreement.name | highlight: search"></span>

          <span
            *ngIf="agreement.objectName"
            matListItemLine
            [innerHTML]="agreement.objectName | highlight: search">
          </span>

          <span matListItemLine>
            <span [matTooltip]="'Period' | translate">
              {{ agreement?.from | dater | date: 'shortDate' }} - {{ agreement?.to | dater | date: 'shortDate' }}
            </span>
          </span>

          <span matListItemMeta>
            <ng-container *ngIf="agreement.active">
              <mat-icon
                matListItemIcon
                class="success-color"
                [matTooltip]="'Active' | translate">
                check_circle
              </mat-icon>
            </ng-container>
          </span>
        </mat-list-item>
      </ng-container>

      <mat-paginator
        class="AK-50-bg"
        [pageSizeOptions]="[5, 10, 25, 50]"
        [length]="count"
        [pageIndex]="page"
        [pageSize]="limit"
        (page)="onChangePage($event)">
      </mat-paginator>
    </mat-nav-list>
  </section>
</ng-container>
